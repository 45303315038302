import React from 'react';
import styled from '@emotion/styled';
import { Weight } from 'renderer/utils/styled';

export enum CaptionMode {
  PRIMARY,
  SECONDARY,
}
type CaptionProps = ModeProps & {
  children: string | React.ReactNode;
};

type ModeProps = {
  mode?: CaptionMode;
};
const Copy = styled.div<ModeProps>(({ theme, mode = CaptionMode.PRIMARY }) => [
  {
    fontSize: 10,
    lineHeight: '1.5em',
    fontWeight: Weight.REGULAR,
    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
  },
  mode === CaptionMode.PRIMARY && {
    color: theme.colors.white,
  },
  mode === CaptionMode.SECONDARY && {
    color: theme.colors.gray4,
  },
]);

const Caption: React.FC<CaptionProps> = ({ children, mode = CaptionMode.PRIMARY }) => {
  return <Copy mode={mode}>{children}</Copy>;
};

export default Caption;
