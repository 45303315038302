import React, { KeyboardEventHandler, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';

import * as s from './styles';

type PublicProps = {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: HTMLTextAreaElement['disabled'];
  children?: React.ReactNode;
};

type PrivateProps = PublicProps & {
  value: string;
  onkeydown?: KeyboardEventHandler;
  autocomplete?: HTMLTextAreaElement['autocomplete'];
  maxLength?: number;
  placeholder?: HTMLTextAreaElement['placeholder'];
  readonly?: HTMLTextAreaElement['readOnly'];
  rows?: HTMLTextAreaElement['rows'];
  tabIndex?: number;
  error?: string | null;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export const Private: React.FunctionComponent<PrivateProps> = ({
  autocomplete,
  disabled,
  error,
  onkeydown,
  label,
  maxLength,
  onBlur,
  onChange,
  placeholder,
  readonly,
  rows = 1,
  tabIndex,
  value,
  children,
}) => {
  return (
    <s.Field>
      {label && <s.Label>{label}</s.Label>}
      {error && <s.ErrorMessage>{error}</s.ErrorMessage>}
      <s.Workarea>
        <s.TextArea
          autoComplete={autocomplete}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={onBlur}
          onKeyDown={onkeydown}
          onChange={onChange}
          placeholder={placeholder ?? label}
          readOnly={readonly}
          rows={rows}
          tabIndex={tabIndex}
          value={value}
        />
        {children && <s.Children>{children}</s.Children>}
      </s.Workarea>
    </s.Field>
  );
};

const Public: React.FunctionComponent<PublicProps> = ({ name, ...rest }) => {
  const [field, meta, helper] = useField(name);
  const context = useFormikContext();

  const handleOnBlur = useCallback(
    (event: any) => {
      if (field.onBlur) {
        field.onBlur(event);
      }

      field.onBlur(event);
    },
    [field]
  );

  //console.log(field, meta, helper, context);

  const handleOnChange = useCallback(
    (event: any) => {
      helper.setValue(event.target.value);
    },
    [helper]
  );

  const handleSubmit = useCallback(
    async (event: any) => {
      const isMetaEnter = event.metaKey && event.code === 'Enter';
      const isCtrlEnter = event.ctrlKey && event.code === 'Enter';
      if (isMetaEnter || isCtrlEnter) {
        await context.submitForm();
      }
    },
    [context]
  );

  return (
    <Private
      {...rest}
      name={name}
      error={meta.touched && meta.error ? meta.error : null}
      onkeydown={handleSubmit}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      value={field.value}
    />
  );
};

export default Public;
