import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import * as analytics from 'renderer/utils/analytics';
import { SentryRoutes } from 'renderer/utils/errors';

import { EnsureAuth, EnsureInvite } from 'renderer/components/misc/EnsureAuth';

import InvitationManager from 'renderer/components/pages/InvitationManager';
import ResetPasswordManager from 'renderer/components/pages/ResetPasswordManager';
import DownloadManager from 'renderer/components/pages/DownloadManager';
import LoginManager from 'renderer/components/pages/LoginManager';
import NewGameSession from 'renderer/components/pages/NewGameSession';
import BranchSelection from 'renderer/components/pages/BranchSelection';
import GameSelection from 'renderer/components/pages/GameSelection';
import GameSession from 'renderer/components/pages/GameSession';

import { useGetUserQuery } from './queries';

export default () => {
  const userQuery = useGetUserQuery();
  const auth = userQuery.data?.auth ?? null;

  useEffect(() => {
    if (!auth) return;

    analytics.identify(auth.id, {
      id: auth.id,
      name: `${auth.user.firstName} ${auth.user.lastName}`,
      email: auth.email ?? 'unknown email',
    });
  }, [auth]);

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="/auth">
          <Route path="invitation/:invitationToken" element={<InvitationManager />} />
          <Route path="reset-password/:resetToken" element={<ResetPasswordManager />} />
          <Route path="login/" element={<LoginManager />} />
        </Route>
        <Route path="/" element={<EnsureInvite />}>
          <Route path="/" element={<GameSelection />} />
          <Route path="/games" element={<GameSelection />} />
          <Route path="/game">
            <Route path="new" element={<GameSelection />} />
            <Route path=":shortname" element={<BranchSelection />} />
            <Route path=":shortname/new" element={<NewGameSession />} />
            <Route path="session/:sessionId" element={<GameSession />} />
          </Route>
          <Route path="/" element={<EnsureAuth />}>
            <Route path="downloads/" element={<DownloadManager />} />
          </Route>
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
};
