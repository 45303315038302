import React from 'react';
import styled from '@emotion/styled';

import { border, padding } from 'polished';
import { ContextTypes } from 'themes';

type MainProps = {
  horizontal?: number;
  vertical?: number;
  children: React.ReactNode;
};

export const Main = styled.div<MainProps>(({ theme, horizontal = 1, vertical = 1 }) => [
  {
    ...padding(5 * vertical, 5 * horizontal),
    overflow: 'auto',
  },
  theme.styles.context === ContextTypes.Menu && {
    ...border('bottom', 1, 'solid', theme.colors.gray2),

    '&:last-child': {
      border: 'none',
    },
  },
]);

const Section: React.FC<MainProps> = ({ children, horizontal = 1, vertical = 1 }) => {
  return (
    <Main horizontal={horizontal} vertical={vertical}>
      {children}
    </Main>
  );
};

export default Section;
