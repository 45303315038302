import React from 'react';
import styled from '@emotion/styled';

const Svg = styled.svg(() => ({
  cursor: 'inherit',
  pointerEvents: 'none',
}));

const Path = styled.path(() => ({
  fill: 'currentcolor',
}));

const User: React.FC = () => (
  <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6 9.5L5.4 9.5L5.4 8.3L6.6 8.3L6.6 9.5ZM6.6 6.5C6.6 6.83137 6.33137 7.1 6 7.1C5.66863 7.1 5.4 6.83137 5.4 6.5L5.4 4.1C5.4 3.76863 5.66863 3.5 6 3.5C6.33137 3.5 6.6 3.76863 6.6 4.1L6.6 6.5ZM6 12.5C9.312 12.5 12 9.812 12 6.5C12 3.188 9.312 0.5 6 0.499999C2.688 0.499999 8.14081e-07 3.188 5.24537e-07 6.5C2.34992e-07 9.812 2.688 12.5 6 12.5ZM6 1.7C8.646 1.7 10.8 3.854 10.8 6.5C10.8 9.146 8.646 11.3 6 11.3C3.354 11.3 1.2 9.146 1.2 6.5C1.2 3.854 3.354 1.7 6 1.7Z"
      fill="#828282"
    />
  </Svg>
);

export default User;
