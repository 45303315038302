import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Form, Formik } from 'formik';
import Text from 'renderer/components/inputs/Text';
import Hero, { Main, RightSidebar, Notification } from 'renderer/components/templates/Hero';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';
import Alert from 'renderer/system/atoms/Alert';
// import Button, {ButtonModes} from 'renderer/system/atoms/Button';

import { useSessionRef, useLocalRef } from 'renderer/hooks/useCacheState';
import Button from 'renderer/system/atoms/Button';
import HiddenSubmit from 'renderer/components/inputs/HiddenSubmit';
import { useUserQuery, useLoginMutation } from './queries';
import { useNotifications } from 'renderer/hooks/useNotification';

type LoginForm = {
  username: string;
  password: string;
};

const loginInitialValues: LoginForm = {
  username: '',
  password: '',
};

const LoginManager: React.FC = () => {
  const notifications = useNotifications();
  const userQuery = useUserQuery();
  const navigate = useNavigate();
  const [, setAccessToken] = useLocalRef('settings.access-token', null);
  const [postAuth, setPostAuth] = useSessionRef<string | null>('settings.post-auth', null);

  const [login] = useLoginMutation();

  const handleSubmitLogin = async ({ username, password }: LoginForm) => {
    const result = await login({ variables: { username, password } });
    setAccessToken(result.data?.login?.accessToken);
    await userQuery.refetch();

    if (postAuth) {
      setPostAuth(null);
      navigate(postAuth);
    } else {
      navigate('/');
    }
  };

  return (
    <Hero backgroundImage={SkyImage}>
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        <Formik<LoginForm> initialValues={loginInitialValues} onSubmit={handleSubmitLogin}>
          {({ submitForm }) => (
            <Form>
              <Text label="Username" name="username" />
              <Text label="Password" name="password" type="password" />
              <Button onClick={submitForm}>Login</Button>
              <HiddenSubmit />
            </Form>
          )}
        </Formik>
        {/*<Button mode={ButtonModes.TERTIARY}>New game</Button>*/}
      </RightSidebar>
      {notifications.map((notification) => (
        <Notification key={notification.id}>
          <Alert>{notification.content}</Alert>
        </Notification>
      ))}
    </Hero>
  );
};

export default observer(LoginManager);
