import styled from '@emotion/styled';

export const Main = styled.canvas(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: '#000',
}));

export const Scroll = styled.div(() => ({
  overflowY: 'auto',
}));
