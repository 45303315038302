import styled from '@emotion/styled';
import { Grid } from 'renderer/utils/styled';

export const Main = styled.div(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: '#000',
}));

export const Content = styled.div(() => ({
  background: '#000',
  padding: Grid.L,
  margin: Grid.L,
}));
