import React, { useEffect, useContext } from 'react';

export type Transformer<DATA, PATCH> = (data: PATCH, previous: DATA) => DATA;
export type RenderSender<PATCH> = { sendToEditor: (data: PATCH) => void };
export type MarkReady = () => void;
export type DispatchSender<PATCH> = { sendToCanvas: (data: PATCH) => void };
export type MockProviderProps<PATCH, DATA extends Subscribable<PATCH>> = {
  data: DATA;
  children: React.ReactNode;
};
export type Subscribable<PATCH> = {
  receiveFromRenderer(patch: PATCH, id: string): void;
};

//getMemoryAssets<K extends keyof Scene>(key: K): ValueOf<Scene[K]>[] {

export type Renderer<PATCH, DATA extends Subscribable<PATCH>> = {
  useRenderer: () => DATA;
  senders: { [index: string]: (patch: PATCH) => void };
  buses: { [index: string]: GameStateBus };
  Provider: React.FC<React.PropsWithChildren>;
  MockProvider: React.FC<MockProviderProps<PATCH, DATA>>;
};

export class GameStateBus {
  markReady: () => void;
  rte: string;
  etr: string;

  constructor(rte: string, etr: string, markReady: () => void) {
    this.markReady = markReady;
    this.rte = rte;
    this.etr = etr;
  }

  postMessage(message: string) {
    const data = JSON.parse(message);
    //this.sender.sendToEditor(data);
    const event = new CustomEvent(this.rte, { detail: data });
    window.dispatchEvent(event);
  }

  startListening(fn: any) {
    // @ts-ignore
    window.addEventListener(this.etr, (event: CustomEvent) => {
      const content = JSON.stringify(event.detail);
      fn(content);
    });
  }
}

export const createRenderer = <PATCH, DATA extends Subscribable<PATCH>>(
  store: DATA,
  /// TODOS: I would love for these IDs to be typed, but alas...
  ids: readonly string[]
): Renderer<PATCH, DATA> => {
  const Context = React.createContext<DATA | null>(null);

  const isReady: { [key: string]: boolean } = {};

  const cache: { [key: string]: PATCH[] } = {};
  //const transformer: Transformer<DATA, PATCH> = process;

  const MockProvider: React.FC<MockProviderProps<PATCH, DATA>> = ({ children, data }) => {
    return <Context.Provider value={data}>{children}</Context.Provider>;
  };

  const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
    useEffect(() => {
      for (const id of ids) {
        const rte = `${id}.renderer-to-editor`;
        // @ts-ignore
        window.addEventListener(rte, (event: CustomEvent) => {
          store.receiveFromRenderer(event.detail, id);
        });
      }
    }, []);

    useEffect(() => {
      for (const id of ids) {
        const etr = `${id}.editor-to-renderer`;
        const btr = `${id}.bus-to-renderer`;
        // @ts-ignore
        window.addEventListener(btr, (event: CustomEvent) => {
          if (isReady[id]) {
            const packet = new CustomEvent(etr, { detail: event.detail });
            window.dispatchEvent(packet);
          } else {
            if (!cache[id]) {
              cache[id] = [];
            }
            cache[id].push(event.detail);
          }
        });
      }
    }, []);

    return <Context.Provider value={store}>{children}</Context.Provider>;
  };

  const useRenderer = (): DATA => {
    const data = useContext(Context);

    if (!data) throw Error("You shouldn't be using this data outside of the provider");

    return data;
  };

  const buses = {};

  for (const id of ids) {
    const etr = `${id}.editor-to-renderer`;
    const rte = `${id}.renderer-to-editor`;
    const markReady = () => {
      isReady[id] = true;

      let patch: PATCH | undefined = undefined;
      const messages = cache[id];

      if (!messages) {
        // TODO: retry
        return;
      }

      // console.log('marking ready', [...messages]);

      while ((patch = messages.pop())) {
        const event = new CustomEvent(etr, { detail: patch });
        window.dispatchEvent(event);
      }
    };

    const bus = new GameStateBus(rte, etr, markReady);
    // @ts-ignore
    buses[id] = bus;
  }

  const senders = {};

  for (const id of ids) {
    const btr = `${id}.bus-to-renderer`;
    const sendToRenderer = (patch: PATCH) => {
      const event = new CustomEvent(btr, { detail: patch });
      window.dispatchEvent(event);
    };
    // @ts-ignore
    senders[id] = sendToRenderer;
  }

  return {
    useRenderer,
    senders,
    buses,
    Provider,
    MockProvider,
  };
};
