import { useState, useEffect } from 'react';
import { getGPUTier, TierResult } from 'detect-gpu';

// @ts-ignore
import * as lib from '~engine/studio.js'; //eslint-disable-line import/extensions

export const useGpuInfo = (): any | null => {
  const [gpu, setGpu] = useState<any | null>(null);

  useEffect(() => {
    const effect = async () => {
      const tier = await getGPUTier();
      setGpu(tier);
    };
    effect();
  }, []);

  return gpu;
};

export const useGpu = (): TierResult | null => {
  const [gpu, setGpu] = useState<lib.GpuTier | null>(null);

  useEffect(() => {
    const effect = async () => {
      const tier = await getGPUTier();
      let gpuTier = lib.GpuTier.Integrated;
      switch (tier.tier) {
        case 0: {
          gpuTier = lib.GpuTier.Integrated;
          break;
        }
        case 1: {
          gpuTier = lib.GpuTier.Low;
          break;
        }
        case 2: {
          gpuTier = lib.GpuTier.Mid;
          break;
        }
        case 3: {
          gpuTier = lib.GpuTier.High;
          break;
        }
      }
      setGpu(gpuTier);
    };
    effect();
  }, []);

  return gpu;
};

export const loadGpuInfo = async (): lib.GpuTier => {
  const tier = await getGPUTier();

  let gpuTier = lib.GpuTier.Integrated;
  switch (tier.tier) {
    case 0: {
      gpuTier = lib.GpuTier.Integrated;
      break;
    }
    case 1: {
      gpuTier = lib.GpuTier.Low;
      break;
    }
    case 2: {
      gpuTier = lib.GpuTier.Mid;
      break;
    }
    case 3: {
      gpuTier = lib.GpuTier.High;
      break;
    }
  }

  return gpuTier;
};
