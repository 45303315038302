import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSessionQueryVariables = Types.Exact<{
  sessionId: Types.Scalars['String'];
}>;


export type GetSessionQuery = { __typename?: 'QueryRoot', auth?: { __typename?: 'Auth', user: { __typename?: 'User', id: string, firstName: string, lastName: string }, invitationInfoBySessionId: { __typename?: 'InvitationInfo', used: number, allowed?: number | null, invitationToken: any } } | null, session?: { __typename?: 'Session', id: string, allocation?: { __typename?: 'Allocation', gameServerName: string, tcp: string, udp: string } | null, version: { __typename?: 'Version', id: string, sha: string, executable?: string | null, environment: Types.Environment, clientStorage?: string | null, createdAt: any, updatedAt: any, branch: { __typename?: 'Branch', id: string, game: { __typename?: 'Game', id: string, name: string } } } } | null };


export const GetSessionDocument = gql`
    query GetSession($sessionId: String!) {
  auth {
    user {
      id
      firstName
      lastName
    }
    invitationInfoBySessionId(sessionId: $sessionId) {
      used
      allowed
      invitationToken
    }
  }
  session(sessionId: $sessionId) {
    id
    allocation {
      gameServerName
      tcp
      udp
    }
    version {
      id
      sha
      executable
      environment
      clientStorage
      createdAt
      updatedAt
      branch {
        id
        game {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionQuery(baseOptions: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
      }
export function useGetSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
        }
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;