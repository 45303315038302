import { Theme } from '@emotion/react';
import { rgba } from 'polished';

import { BackgroundTypes, ContextTypes } from 'themes';

export enum LineHeight {
  NONE = 0,
  SINGLE = '1.0em',
  NORMAL = '1.5em',
}

export enum Weight {
  LIGHT = 200,
  REGULAR = 400,
  SEMIBOLD = 600,
  BOLD = 700,
  BLACK = 900,
}

export enum Radius {
  S = 4,
  M = 8,
  L = 16,
  ROUND = 99999,
}

export enum Grid {
  NONE = 0,
  S = 4,
  M = 8,
  L = 16,
}

export enum Viewports {
  None = 0,
  Phone = 600,
  TabletPortrait = 900,
  TabletLandscape = 1200,
  Desktop = 1800,
}

export const media = {
  between: (min: Viewports, max: Viewports, styles: any) => ({
    [`@media (min-width: ${min}px) and (max-width: ${max - 1}px)`]: styles,
  }),

  min: (min: Viewports, styles: any) => ({
    [`@media (min-width: ${min}px)`]: styles,
  }),

  max: (max: Viewports, styles: any) => ({
    [`@media (max-width: ${max - 1}px)`]: styles,
  }),
};

export const defaultTheme: Theme = {
  styles: {
    background: BackgroundTypes.Light,
    context: ContextTypes.Regular,
  },
  colors: {
    gray0: '#1c1c1c',
    gray1: '#333333',
    gray2: '#4f4f4f',
    gray3: '#828282',
    gray4: '#bdbdbd',
    gray5: '#e0e0e0',
    gray6: '#f2f2f2',

    black: '#000000',
    white: '#ffffff',

    red: '#eb5757',
    orange: '#f2994a',
    yellow: '#f2c94c',

    green1: '#219653',
    green2: '#27ae60',
    green3: '#6fcf97',

    blue1: '#2f80ed',
    blue2: '#2d9cdb',
    blue3: '#56ccf2',

    purple1: '#9b51e0',
    purple2: '#bb6bd9',

    brand: '#00f0ff',

    info: '#e6e9ea',
    success: '#ade9ba',
    warning: '#f7cfa1',
    failure: '#f8d6d5',
  },
  fonts: {
    sans: "'Inter', sans-serif",
  },
  legacy: {
    styles: {
      background: BackgroundTypes.Light,
      context: ContextTypes.Regular,
      dialog: false,
    },
    colors: {
      black: '#010001',

      gray0: '#1f2222',
      gray1: '#2e2e2e',
      gray2: '#3f3f3f',
      gray3: '#ABABAB',
      gray4: '#D7D7D7',
      gray5: '#eee',
      gray6: '#F2F2F2',

      white: '#FFFFFF',

      orange: '#c76d20',
      red: '#E20000',
      lightRed: '#B95151',
      green: '#45A85A',
      darkBrown: '#8e602e',

      faded: '#B9A7A7',

      error: '#F0BDBD',

      primary: '#E20000',
      secondary: '#CD7642',

      brown: '#B8A6A6',
    },
    transparency: {
      gray0: rgba(41, 41, 41, 0.7),
      glass: rgba('#C95555', 0.17),
    },
    textShadow: {
      regular: '2px 2px 1px #b3b3b3',
    },
    fonts: {
      primary: "'Roboto', sans-serif",
      secondary: "'Roboto', sans-serif",
    },
  },
};

export const darkTheme: Theme = {
  ...defaultTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...defaultTheme.legacy.styles,
      background: BackgroundTypes.Dark,
    },
  },
};

export const bannerTheme: Theme = {
  ...defaultTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...darkTheme.legacy.styles,
      context: ContextTypes.Banner,
    },
  },
};

export const darkHeroTheme: Theme = {
  ...darkTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...darkTheme.legacy.styles,
      context: ContextTypes.Hero,
    },
  },
};

export const dialogDarkTheme: Theme = {
  ...darkTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...darkTheme.legacy.styles,
      context: ContextTypes.Dialog,
      dialog: true,
    },
  },
};

export const lightHeroTheme: Theme = {
  ...defaultTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...defaultTheme.legacy.styles,
      context: ContextTypes.Hero,
    },
  },
};

export const metaTheme: Theme = {
  ...defaultTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...defaultTheme.legacy.styles,
      context: ContextTypes.Meta,
    },
  },
};

export const sidebarTheme: Theme = {
  ...darkTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...darkTheme.legacy.styles,
      context: ContextTypes.Sidebar,
    },
  },
};

export const sidebarNavigationTheme: Theme = {
  ...sidebarTheme,
  legacy: {
    ...defaultTheme.legacy,
    styles: {
      ...sidebarTheme.legacy.styles,
      context: ContextTypes.Menu,
    },
  },
};
