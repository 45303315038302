import React, { useEffect } from 'react';

import { Provider as UiProvider } from 'renderer/data/ui';
import { LevelProvider } from 'renderer/data/level';
import { Provider as NotificationProvider } from 'renderer/hooks/useNotification';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import link from 'renderer/utils/link';
import * as analytics from 'renderer/utils/analytics';
import PlatformRouter from 'renderer/components/routers/PlatformRouter';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

const client = new ApolloClient(link);

export const Platform = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_ID || '',
  // We need to pass a context here, but we can override it later
  // with the `useLDClient()` hook.
  context: {
    type: 'user',
    name: process.env.REACT_APP_LD_CONTEXT_NAME || 'Studio',
    key: process.env.REACT_APP_LD_CONTEXT_KEY || 'studio',
    email: process.env.REACT_APP_LD_CONTEXT_EMAIL || 'studio@example.com',
  },
})(() => {
  useEffect(() => {
    analytics.init();
    // errors.init(history);
  }, []);

  return (
    <ApolloProvider client={client}>
      <UiProvider>
        <LevelProvider>
          <NotificationProvider>
            <PlatformRouter />
          </NotificationProvider>
        </LevelProvider>
      </UiProvider>
    </ApolloProvider>
  );
});
