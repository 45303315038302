import React, { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams, useParams } from 'react-router-dom';
import * as uuid from 'uuid';

import Hero, { BackgroundType, Main, RightSidebar } from 'renderer/components/templates/Hero';
import Simple, { Right, Main as SimpleMain } from 'renderer/components/templates/Simple';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';
import LongLogo from 'renderer/images/long-logo.png';
import Tile from './blueTestTile.png';

import BestExperience from './best-experience.svg';
import Browse from './browse.svg';
import NavigateToFirefox from './navigate.svg';
import Options from './options.svg';
import SelectHighPerformance from './preference.svg';

import Title2, { TitleMode } from 'renderer/system/typography/Title2';
import Body from 'renderer/system/typography/Body';
import { NetworkedCanvas } from '@ultimate-games/canvas';

import { useLocalRef } from 'renderer/hooks/useCacheState';
import { useGetSessionQuery } from './queries';
import { useGpuInfo } from 'renderer/hooks/useGpu';

import { Environment } from 'types';
import platform from 'platform-detect';
import { CenterType } from 'renderer/components/templates/Hero/styles';
import Section from 'renderer/system/misc/Section';
import * as s from './styles';
import SessionCommentsManager from 'renderer/components/panels/SessionCommentsManager';

type GameSession = {
  sessionId: string;
};

const GameSession: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const gpu = useGpuInfo();
  const { sessionId } = useParams<GameSession>();
  const [accessToken] = useLocalRef<string | null>('settings.access-token', null);
  const sessionQuery = useGetSessionQuery({
    variables: { sessionId: sessionId ?? '' },
  });
  const user = sessionQuery.data?.auth?.user ?? null;
  const session = sessionQuery.data?.session ?? null;
  const info = sessionQuery.data?.auth?.invitationInfoBySessionId;

  console.info('gpu', gpu, platform);

  useEffect(() => {
    if (!info) return;

    setSearchParams({ invite: info.invitationToken }, { replace: true });
  }, [info, setSearchParams]);

  const startTime = useMemo<number>(() => {
    return new Date().getTime();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  if (gpu?.tier < 2 && !platform.macos && platform.firefox) {
    return (
      <Hero backgroundImage={Tile} backgroundType={BackgroundType.Tiled} centerType={CenterType.Large}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <s.Content>
            <Section vertical={6}>
              <img src={BestExperience} style={{ maxWidth: '100%' }} />
            </Section>
            <Section vertical={6}>
              <img src={Browse} style={{ maxWidth: '100%' }} />
            </Section>
            <Section vertical={6}>
              <img src={NavigateToFirefox} style={{ maxWidth: '100%' }} />
            </Section>
            <Section vertical={6}>
              <img src={Options} style={{ maxWidth: '100%' }} />
            </Section>
            <Section vertical={6}>
              <img src={SelectHighPerformance} style={{ maxWidth: '100%' }} />
            </Section>
          </s.Content>
        </RightSidebar>
      </Hero>
    );
  } else if (gpu?.tier < 2 && !platform.macos && platform.chrome) {
    // GPU is too slow, but we can still help the user
    return (
      <Hero backgroundImage={Tile} backgroundType={BackgroundType.Tiled} centerType={CenterType.Large}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <img src={LongLogo} style={{ width: 400, height: 45, paddingBottom: 10 }} />
          <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}>
            <iframe
              // @ts-ignore
              credentialLess="true"
              referrerPolicy="origin-when-cross-origin"
              src="https://www.loom.com/embed/bfaec837dbba46d98f7b8aa82e1fa214?sid=ca38dae9-99ee-4cfa-895c-9938fb5ca39b"
              frameBorder="0"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </div>
          <div style={{ paddingTop: 10 }}>
            <Title2 mode={TitleMode.TERTIARY}>For the best experience</Title2>
            <Body>
              follow the instructions below to ensure your browser is using a dedicated graphics card.
            </Body>
          </div>
        </RightSidebar>
      </Hero>
    );
  } else if (gpu?.tier < 2) {
    // GPU is too slow
    return (
      <Hero backgroundImage={Tile} backgroundType={BackgroundType.Tiled} centerType={CenterType.Large}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <img src={LongLogo} style={{ width: 400, height: 45, paddingBottom: 10 }} />
          <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}>
            <iframe
              // @ts-ignore
              credentialLess="true"
              referrerPolicy="origin-when-cross-origin"
              src="https://www.loom.com/embed/bfaec837dbba46d98f7b8aa82e1fa214?sid=ca38dae9-99ee-4cfa-895c-9938fb5ca39b"
              frameBorder="0"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </div>
          <div style={{ paddingTop: 10 }}>
            <Title2 mode={TitleMode.TERTIARY}>For the best experience</Title2>
            <Body>
              follow the instructions below to ensure your browser is using a dedicated graphics card.
            </Body>
          </div>
        </RightSidebar>
      </Hero>
    );
  }

  if (!gpu || !accessToken || !user || !session || sessionQuery.loading) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  if (!sessionId) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Session is not defined</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  console.info(session);
  const mediaUrl = process.env.REACT_APP_MEDIA_WS_URL ?? 'ws://unknown';
  const recordSettings: [string, string, string] = [mediaUrl, session.version.branch.game.id, sessionId];

  const config = {
    accessToken,
    assetLocation: `${session.version.clientStorage}/resources/`,
    clientJs: `${session.version.clientStorage}/dist/pkg/${session.version.executable}.js`,
    sessionId,
    connectionId: uuid.v4(),
    storageLocation: `${session.version.clientStorage}/`,
    tcpUrl: `${session.allocation?.tcp ?? ''}`,
    udpUrl: `${session.allocation?.udp ?? ''}`,
    recordSettings,
  };

  const getCurrentTime = (): number => {
    const time = new Date().getTime();
    return (time - startTime) / 1000;
  };

  return (
    <Simple>
      <SimpleMain>
        <NetworkedCanvas config={config} />
      </SimpleMain>
      {session.version.environment !== Environment.Production && (
        <Right>
          <SessionCommentsManager id={sessionId} getCurrentTime={getCurrentTime} />
        </Right>
      )}
    </Simple>
  );
};

export default observer(GameSession);
