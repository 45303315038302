import React, { useContext, useCallback, useEffect, useState } from 'react';
import * as uuid from 'uuid';

type Event = {
  content: string;
  timeout: number;
};

type Notification = Event & {
  id: string;
};

type Notifications = Notification[];
type Sender = (content: string, timeout: number) => Promise<void>;

const Context = React.createContext<Notifications>([]);

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [data, setter] = useState<Notifications>([]);

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('notification', (event: CustomEvent) => {
      const id = uuid.v4();
      setter((prev: Notification[]) => [...prev, { ...event.detail, id }]);

      setTimeout(() => {
        setter((prev: Notification[]) => {
          const filtered = prev.filter((item) => item.id !== id);
          return filtered;
        });
      }, event.detail.timeout);
    });
  }, []);

  return <Context.Provider value={data}>{children}</Context.Provider>;
};

export const useNotifications = (): Notifications => {
  const data = useContext(Context);

  return data;
};

export const useNotificationManager = (): Sender => {
  const send = useCallback(async (content: string, timeout: number): Promise<void> => {
    const event = new CustomEvent('notification', { detail: { content, timeout } });
    window.dispatchEvent(event);
  }, []);

  return send;
};

type LocalNotifications = [Notifications, (content: string, timeout: number) => void];

export const useLocalNotifications = (): LocalNotifications => {
  const [data, setter] = useState<Notifications>([]);

  const creator = (content: string, timeout: number) => {
    const id = uuid.v4();
    const newNotification = { id, content, timeout };
    setter((prev: Notification[]) => [...prev, newNotification]);

    setTimeout(() => {
      setter((prev: Notification[]) => {
        const filtered = prev.filter((item) => item.id !== id);
        return filtered;
      });
    }, timeout);
  };

  return [data, creator];
};
