import React from 'react';

import Button, { ButtonModes } from 'renderer/system/atoms/Button';

import * as s from './styles';

export { CardModes } from './styles';

type Action = {
  label: string;
  action: () => void;
};

type CardProps = {
  header: string;
  mode: s.CardModes;
  actions?: React.ReactNode | null;
  children?: string | React.ReactNode | null;
  subheader?: string | null;
  primary?: Action;
  secondary?: Action;
  tertiary?: Action;
  onClick?: () => void;
  title?: string;
  image?: {
    source: string;
    description: string;
  };
};

const Card: React.FC<CardProps> = ({
  children,
  mode,
  image,
  actions,
  header,
  subheader,
  primary,
  secondary,
  tertiary,
  title,
  onClick,
}) => {
  return (
    <s.Main mode={mode} onClick={onClick} title={title}>
      {image && <s.Image mode={mode} src={image.source} alt={image.description} crossOrigin="anonymous" />}
      <s.Content mode={mode} image={image}>
        <s.Section mode={mode} style={{ width: '100%' }}>
          <s.Label>
            <s.Header mode={mode}>{header}</s.Header>
            {actions && <div>{actions}</div>}
          </s.Label>
          {subheader && <s.Subheader>{subheader}</s.Subheader>}
        </s.Section>
        {children && <s.Body mode={mode}>{children}</s.Body>}
      </s.Content>
      <s.Actions mode={mode}>
        {(primary || secondary || tertiary) && (
          <div>
            {primary && (
              <Button mode={ButtonModes.PRIMARY} onClick={primary.action}>
                {primary.label}
              </Button>
            )}
            {secondary && (
              <Button mode={ButtonModes.TERTIARY} onClick={secondary.action}>
                {secondary.label}
              </Button>
            )}
            {tertiary && (
              <Button mode={ButtonModes.TERTIARY} onClick={tertiary.action}>
                {tertiary.label}
              </Button>
            )}
          </div>
        )}
      </s.Actions>
    </s.Main>
  );
};

export default Card;
