import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { groupBy } from 'lodash';
import { Helmet } from 'react-helmet';

import { darkTheme } from 'renderer/utils/styled';

export { BackgroundType } from './styles';
import * as s from './styles';

export const Main: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Main.displayName = 'main';

export const RightSidebar: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
RightSidebar.displayName = 'rightSidebar';

export const Searchbar: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Searchbar.displayName = 'searchbar';

export const Notification: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Notification.displayName = 'notifications';

type ViewerProps = {
  version?: string;
  backgroundImage: string;
  backgroundType?: s.BackgroundType;
  centerType?: s.CenterType;
  keystone?: React.ReactNode | null;
  children: React.ReactNode;
};

const Viewer: React.FC<ViewerProps> = ({
  children,
  backgroundImage,
  backgroundType,
  centerType,
  keystone,
  version,
}) => {
  const nodes = React.Children.toArray(children);

  const {
    //main,
    rightSidebar,
    searchbar,
    notifications,
    dialog,
    // @ts-ignore
  } = groupBy(nodes, (node) => node.type.displayName);

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>Ultimate</title>
      </Helmet>
      <s.Wrap>
        <s.Main backgroundImage={backgroundImage} backgroundType={backgroundType}>
          <s.Right centerType={centerType}>
            {keystone && <s.Keystone>{keystone}</s.Keystone>}
            <s.Panel>
              <s.Scroll>{rightSidebar}</s.Scroll>
            </s.Panel>
            {version && <s.Version>{version}</s.Version>}
          </s.Right>
        </s.Main>
        <s.Searchbar>{searchbar}</s.Searchbar>
      </s.Wrap>
      {notifications?.length > 0 && <s.Notifications>{notifications}</s.Notifications>}
      {dialog?.length > 0 && <s.Dialogs>{dialog}</s.Dialogs>}
    </ThemeProvider>
  );
};

export default Viewer;
