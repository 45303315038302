import React from 'react';
import styled from '@emotion/styled';
import { padding } from 'polished';

import InfoIcon from 'renderer/icons/light/Info';
import SuccessIcon from 'renderer/icons/light/Success';
import WarningIcon from 'renderer/icons/light/Warning';
import FailureIcon from 'renderer/icons/light/Failure';

import { Weight, Radius, Grid, LineHeight } from 'renderer/utils/styled';

export enum BannerType {
  INFO,
  SUCCESS,
  WARNING,
  FAILURE,
}

type BannerProps = {
  bannerType?: BannerType;
};

const Banner = styled.div<BannerProps>(({ theme, bannerType }) => [
  {
    borderRadius: Radius.M,
    transition: 'color 300ms ease-in-out,background 300ms ease-in-out',
    ...padding(Grid.M, Grid.L),
  },
  bannerType === BannerType.INFO && {
    background: theme.colors.info,
    color: theme.colors.gray0,
  },
  bannerType === BannerType.SUCCESS && {
    background: theme.colors.success,
    color: theme.colors.green1,
  },
  bannerType === BannerType.WARNING && {
    background: theme.colors.warning,
    color: theme.colors.orange,
  },
  bannerType === BannerType.FAILURE && {
    background: theme.colors.failure,
    color: theme.colors.red,
  },
]);

const Copy = styled.span(({ theme }) => ({
  fontFamily: theme.fonts.sans,
  fontWeight: Weight.REGULAR,
  fontSize: 16,
  lineHeight: LineHeight.NORMAL,
  marginLeft: Grid.M,
}));

type AlertProps = BannerProps & {
  children: string;
};

const Alert: React.FC<AlertProps> = ({ children, bannerType = BannerType.INFO }) => {
  return (
    <Banner bannerType={bannerType}>
      {bannerType === BannerType.INFO && <InfoIcon />}
      {bannerType === BannerType.SUCCESS && <SuccessIcon />}
      {bannerType === BannerType.WARNING && <WarningIcon />}
      {bannerType === BannerType.FAILURE && <FailureIcon />}
      <Copy>{children}</Copy>
    </Banner>
  );
};

export default Alert;
