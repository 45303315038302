import styled from '@emotion/styled';
import { padding } from 'polished';
import { Grid } from 'renderer/utils/styled';

type WrapProps = {
  right: boolean;
};
export const Wrap = styled.div<WrapProps>(({ right }) => {
  const rightArea = right ? 'right-area' : '';
  const rightSize = right ? '305px' : '';
  const gridTemplateColumns = `minmax(0, 1fr) ${rightSize}`;
  const gridTemplateRows = '1fr';
  const gridTemplateAreas = `
    "main ${rightArea}"
  `;

  return {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    display: 'grid',
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    overflow: 'hidden',
  };
});

export const Main = styled.div(({ theme }) => ({
  gridArea: 'main',

  fontFamily: theme.fonts.sans,
  color: theme.colors.white,

  width: '100%',
  height: '100%',
  overflow: 'hidden',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  WebkitAppRegion: 'drag',
}));

export const Scroll = styled.div(() => ({
  height: '100%',
}));

export const Keystone = styled.div(() => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const Version = styled.div(({ theme }) => ({
  position: 'absolute',
  bottom: 10,
  right: 10,
  fontSize: 9,
  color: theme.colors.gray2,
}));

export const Right = styled.div(({ theme }) => ({
  gridArea: 'right-area',

  position: 'relative',
  minWidth: '450px',
  background: theme.colors.gray0,
  WebkitAppRegion: 'no-drag',
}));

export const Searchbar = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  height: '100%',

  boxSizing: 'content-box',
  background: theme.colors.black,
  ...padding(0, Grid.M),
}));

export const Panel = styled.div(() => ({
  pointerEvents: 'auto',
  overflowY: 'auto',

  boxSizing: 'content-box',

  ...padding(Grid.M),
}));

export const Notifications = styled.div(() => ({
  position: 'fixed',
  top: 45,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1001,
}));
