import styled from '@emotion/styled';
import { padding } from 'polished';
import { Weight, Radius, Grid } from 'renderer/utils/styled';

export const ErrorMessage = styled.div(({ theme }) => [
  {
    color: theme.legacy.colors.brown,
    fontSize: 11,
  },
]);

export const Row = styled.div(() => ({
  alignItems: 'baseline',
  gap: '10px',
  ...padding(Grid.M, 0),
}));

export const Label = styled.label(({ theme }) => [
  {
    display: 'block',
    width: '100%',
    fontSize: 13,
    fontFamily: theme.fonts.sans,
    fontWeight: Weight.REGULAR,
    color: theme.colors.gray4,
    ...padding(0, 0, Grid.S),
  },
]);

export const Input = styled.select(({ theme }) => [
  {
    display: 'block',
    width: '100%',
    fontSize: 13,
    borderRadius: Radius.S,
    background: theme.colors.gray1,
    color: theme.colors.gray6,
    fontFamily: theme.fonts.sans,
    border: 0,
    padding: Grid.M,
    outline: 0,
    boxSizing: 'border-box',
  },
]);
