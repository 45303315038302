import React from 'react';
import styled from '@emotion/styled';
import { Grid } from 'renderer/utils/styled';
import { EventProps } from 'renderer/utils/events';

export enum Spacing {
  START,
  EQUALCOLUMNS,
  GAPS,
}

export enum Alignment {
  TOP,
  CENTER,
  BOTTOM,
}

type Props = {
  alignment?: Alignment;
  label?: string;
  children: React.ReactNode;
};

type LineProps = {
  highlight?: boolean;
  spacing?: Spacing;
};

export const Line = styled.div<EventProps & LineProps>(({ theme, onClick, highlight, spacing }) => [
  {
    boxSizing: 'border-box',
    //width: '100%',
  },
  onClick && {
    cursor: 'pointer',
    borderRadius: 3,
    transition: 'background 200ms ease-in-out',

    ':hover': {
      background: theme.colors.gray2,
    },
  },
  spacing === Spacing.GAPS && {
    width: '100%',
  },
  highlight && {
    background: theme.colors.gray1,
  },
]);

type FieldProps = {
  spacing?: Spacing;
  alignment?: Alignment;
};

type CountProps = {
  count?: number;
};

export const Field = styled.div<CountProps & FieldProps>(({ spacing, alignment }) => [
  {
    lineHeight: '1.0em',
  },
  spacing === Spacing.START && {
    display: 'flex',
    alignContent: 'flex-start',
    gap: Grid.M,
  },
  spacing === Spacing.EQUALCOLUMNS && {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: Grid.M,
  },
  spacing === Spacing.GAPS && {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: Grid.M,
  },

  alignment === Alignment.TOP && {
    alignItems: 'flex-start',
  },
  alignment === Alignment.CENTER && {
    alignItems: 'center',
  },
  alignment === Alignment.BOTTOM && {
    alignItems: 'flex-end',
  },
]);

export const Label = styled.div(() => ({
  display: 'flex',
  fontSize: 11,
}));

const Row: React.FunctionComponent<Props & FieldProps & EventProps & LineProps> = ({
  children,
  label = null,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onDragStart,
  highlight = false,
  spacing = Spacing.START,
  alignment = Alignment.TOP,
}) => {
  const nodes = React.Children.toArray(children) ?? [];
  const length = nodes.length ?? 1;
  return (
    <Line
      onClick={onClick}
      spacing={spacing}
      highlight={highlight}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragStart={onDragStart}
      draggable={!!onDragStart}
    >
      {label && <Label>{label}</Label>}
      <Field count={length} spacing={spacing} alignment={alignment}>
        {children}
      </Field>
    </Line>
  );
};

export default Row;
