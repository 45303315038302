export enum ContextTypes {
  Banner = 'Banner',
  Dialog = 'Dialog',
  Focus = 'Focus',
  Glass = 'Glass',
  Hero = 'Hero',
  Menu = 'Menu',
  Meta = 'Meta',
  Regular = 'Regular',
  Sidebar = 'Sidebar',
}

export enum BackgroundTypes {
  Dark = 'Dark',
  Light = 'Light',
}
