// TODO: make a PR to logrocket to have it actually export its types

import logrocket from 'logrocket';

export const init = (): Promise<string | null> =>
  new Promise((resolve) => {
    const logrocketUrl = process.env.REACT_APP_LOGROCKET;
    if (!logrocketUrl) {
      return resolve(null);
    }

    try {
      logrocket.init(logrocketUrl, {
        network: {
          requestSanitizer: (request: any) => {
            if (request.headers.authorization) {
              request.headers.authorization = '';
            }
            return request;
          },
        },
      });
    } catch (error) {
      console.error('Could not initialize Logrocket');
    }

    logrocket.getSessionURL((sessionUrl: string) => {
      resolve(sessionUrl);
    });
  });

// https://docs.logrocket.com/docs/sentry
export const getAnalyticsUrl = async (): Promise<string> => {
  return new Promise((resolve) => {
    logrocket.getSessionURL((sessionUrl) => {
      resolve(sessionUrl);
    });
  });
};

interface UserTraits {
  [propName: string]: string | number | boolean;
}
export const identify = (id?: string, data?: UserTraits) => {
  if (!process.env.REACT_APP_LOGROCKET) {
    return;
  }

  if (!id) {
    return;
  }

  if (data) {
    if (!data.name && data.username) {
      data.name = data.username;
    }
  }

  try {
    logrocket.identify(id, data);
  } catch (error) {
    console.error('Could not identify with Logrocket');
  }
};

/*
export const group = (id: string, data: any) => {
  try {
    analytics.group(id, data);
  } catch (e) {
    console.error('could not identify with segment');
  }
};

export const track = (id: string, data: any) => {
  if (!process.env.REACT_APP_LOGROCKET) return;

  try {
    logrocket.identify(id, data);
  } catch (e) {
    console.error('could not identify with Logrocket');
  }
};

export const page = () => {
  try {
    analytics.page();
  } catch (e) {
    console.error('could not page with segment');
  }
};
*/
