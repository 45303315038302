import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, Navigate } from 'react-router-dom';

import Hero, { Main, RightSidebar } from 'renderer/components/templates/Hero';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';

import Title2 from 'renderer/system/typography/Title2';
import { useAsyncEffect } from 'renderer/hooks/useAsyncEffect';

import { useGetUserQuery, useCreateSessionMutation } from './queries';

type NewGame = {
  shortname: string;
};

const NewGame: React.FC = () => {
  const { shortname } = useParams<NewGame>();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const userQuery = useGetUserQuery();
  const user = userQuery.data?.auth?.user ?? null;
  const [createSession, createStatus] = useCreateSessionMutation();

  useAsyncEffect(async () => {
    if (!shortname) return;

    const session = await createSession({ variables: { shortname } });
    const id = session.data?.createSession.id ?? null;
    if (!id) {
      return;
    }

    setSessionId(id);
  }, []);

  if (!user || userQuery.loading || (createStatus.loading && createStatus.called)) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  if (!shortname) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Not sure which game to initialize</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  if (!sessionId) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  return <Navigate to={`/game/session/${sessionId}`} replace={true} />;
};

export default observer(NewGame);
