import { split, HttpLink, InMemoryCache, from } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

const retryLink = new RetryLink({
  attempts: (_, operation, error) => {
    return !!error && operation.operationName != 'specialCase';
  },
  delay: (count) => {
    return count * 1000;
  },
});

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = localStorage.getItem('settings.access-token');
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? JSON.parse(token) : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const wsLink = new WebSocketLink({
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem('settings.access-token');
      const authorization = token ? JSON.parse(token) : '';
      return {
        authorization,
        headers: {
          authorization,
        },
      };
      //return { authorization };
    },
  },
  uri: process.env.REACT_APP_SUBSCRIPTION_URL ?? 'https://localhost:4000/subscription',
});

const mergedLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);

const link = {
  cache: new InMemoryCache(),
  link: from([retryLink, errorLink, mergedLink]),
  //link: from([retryLink, errorLink, authLink.concat(httpLink)]),
};

export default link;
