import React from 'react';
import { observer } from 'mobx-react';

import { Form, Formik, FormikHelpers } from 'formik';
import Comment, { Footer } from 'renderer/system/molecules/Comment';
import Textarea from 'renderer/components/inputs/Textarea';
import HiddenSubmit from 'renderer/components/inputs/HiddenSubmit';

import Section from 'renderer/system/misc/Section';
import {
  GetSessionCommentsSubscription,
  useCreateSessionCommentMutation,
  useGetSessionCommentsSubscription,
} from './queries';
import * as s from './styles';
import Eyebrow from 'renderer/system/typography/Eyebrow';
import Caption, { CaptionMode } from 'renderer/system/typography/Caption';
import { elapsed } from 'renderer/utils/datetime';

type CreateComment = {
  content: string;
};

type SessionCommentsManagerProps = {
  id: string;
  getCurrentTime: () => number;
  onClickComment?: (
    comment: ArrayElement<GetSessionCommentsSubscription['getSessionComments']>
  ) => () => void;
};

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

const SessionCommentsManager: React.FC<SessionCommentsManagerProps> = ({
  id,
  onClickComment,
  getCurrentTime,
}) => {
  const [create, commentMeta] = useCreateSessionCommentMutation();

  const commentsQuery = useGetSessionCommentsSubscription({
    variables: { id },
  });

  const initialValues = {
    content: '',
  };

  const handleSubmitForm = async (values: CreateComment, formik: FormikHelpers<CreateComment>) => {
    await create({
      variables: {
        sessionId: id,
        offset: getCurrentTime(),
        content: values.content,
      },
    });
    formik.resetForm();
  };

  if (commentsQuery.loading) {
    return <div>Loading...</div>;
  }

  const comments = commentsQuery.data?.getSessionComments ?? [];

  return (
    <s.Area>
      <s.Main>
        <s.Overflow>
          {comments.length <= 0 && (
            <Section horizontal={3} vertical={3}>
              <Eyebrow>No comments yet.</Eyebrow>
            </Section>
          )}
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              title={`${comment.user?.firstName} ${comment.user?.lastName}`}
              time={new Date(comment.createdAt)}
              content={comment.content}
              onClick={onClickComment?.(comment)}
            >
              <Footer>
                <Caption mode={CaptionMode.SECONDARY}>@ {elapsed(comment.offset)}</Caption>
              </Footer>
            </Comment>
          ))}
        </s.Overflow>
      </s.Main>
      <s.Footer>
        <Formik<CreateComment> initialValues={initialValues} onSubmit={handleSubmitForm}>
          {() => (
            <Form>
              <Textarea placeholder="Add comment..." name="content" disabled={commentMeta.loading} />
              <HiddenSubmit />
            </Form>
          )}
        </Formik>
      </s.Footer>
    </s.Area>
  );
};

export default observer(SessionCommentsManager);
