import styled from '@emotion/styled';
import { padding } from 'polished';
import { Grid, Radius } from 'renderer/utils/styled';

export const Wrap = styled.div(() => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'minmax(0, 1fr) 40px',
  gridTemplateAreas: `
    "main"
    "footer"
  `,
}));

export enum BackgroundType {
  Large,
  Tiled,
}

type MainProps = {
  backgroundImage: string;
  backgroundType?: BackgroundType;
};
export const Main = styled.div<MainProps>(
  ({ theme, backgroundImage, backgroundType = BackgroundType.Large }) => [
    {
      gridArea: 'main',

      fontFamily: theme.fonts.sans,
      color: theme.colors.white,

      width: '100%',
      height: '100%',
      overflow: 'hidden',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      WebkitAppRegion: 'drag',
    },
    backgroundType === BackgroundType.Large && {
      background: `url(${backgroundImage}) center center`,
      backgroundSize: 'cover',
    },
    backgroundType === BackgroundType.Tiled && {
      background: `url(${backgroundImage}) repeat center center`,
    },
  ]
);

export const Scroll = styled.div(() => ({
  height: '100%',
}));

export const Keystone = styled.div(() => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const Version = styled.div(({ theme }) => ({
  position: 'absolute',
  bottom: 10,
  right: 10,
  fontSize: 9,
  color: theme.colors.gray2,
}));

export enum CenterType {
  Small,
  Large,
}

type RightProps = {
  centerType?: CenterType;
};
export const Right = styled.div<RightProps>(({ theme, centerType = CenterType.Small }) => [
  {
    borderRadius: Radius.L,
    position: 'relative',
    minWidth: '450px',
    ...padding(Grid.M * 6, Grid.M * 5, Grid.M * 5, Grid.M * 5),
    WebkitAppRegion: 'no-drag',
  },
  centerType === CenterType.Small && {
    background: theme.colors.gray0,
    width: '30%',
    height: '50%',
  },
  centerType === CenterType.Large && {
    width: '60%',
    height: '100%',
    textAlign: 'center',
  },
]);

export const Searchbar = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  height: '100%',
  width: '100%',

  boxSizing: 'border-box',
  background: theme.colors.black,
  ...padding(0, Grid.M),
}));

export const Panel = styled.div(() => ({
  pointerEvents: 'auto',
  overflowY: 'auto',
  maxHeight: '100%',

  boxSizing: 'border-box',

  ...padding(Grid.M),
}));

export const Dialogs = styled.div(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1000,
}));

export const Notifications = styled.div(() => ({
  position: 'fixed',
  top: 45,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1001,
}));
