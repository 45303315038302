import React from 'react';
import styled from '@emotion/styled';
import { Weight } from 'renderer/utils/styled';
import { EventProps } from 'renderer/utils/events';

export enum EyebrowMode {
  PRIMARY,
  SECONDARY,
}
type EyebrowProps = ModeProps & {
  children: string | React.ReactNode;
};

type ModeProps = {
  mode?: EyebrowMode;
};
const Copy = styled.div<ModeProps & EventProps>(({ theme, mode = EyebrowMode.PRIMARY, onClick }) => [
  {
    textTransform: 'uppercase',
    lineHeight: '1.5em',
    fontWeight: Weight.REGULAR,
    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
  },
  mode === EyebrowMode.PRIMARY && {
    fontSize: 11,
    color: theme.colors.gray5,
  },
  mode === EyebrowMode.SECONDARY && {
    fontSize: 10,
    color: theme.colors.gray3,
  },
  onClick && {
    cursor: 'pointer',
  },
]);

const Eyebrow: React.FC<EyebrowProps & EventProps> = ({ children, mode = EyebrowMode.PRIMARY, onClick }) => {
  return (
    <Copy mode={mode} onClick={onClick}>
      {children}
    </Copy>
  );
};

export default Eyebrow;
