import * as Sentry from '@sentry/browser';
import { Wasm } from '@sentry/wasm';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Routes } from 'react-router-dom';

type UserError = {
  id: string;
};

/**
 * Setting `user` to `null` will unset any previously identified user
 */
export const identify = (user: UserError) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
};

export const init = (sessionUrl: string) => {
  const sentryUrl = process.env.REACT_APP_SENTRY_EDITOR;

  if (!sentryUrl) return;

  try {
    Sentry.init({
      dsn: sentryUrl,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost'],
        }),
        new Wasm(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    Sentry.configureScope((scope) => {
      scope.setExtra('Logrocket Session Url', sessionUrl);
    });
  } catch (e) {
    console.error(e);
  }
};

export const SentryRoutes = withSentryReactRouterV6Routing(Routes);
