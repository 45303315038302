import React from 'react';

import styled from '@emotion/styled';

const Main = styled.div(() => [
  {
    position: 'relative',
    width: '100%',
    height: 40,
  },
]);

const Border = styled.div(({ theme }) => [
  {
    position: 'absolute',
    top: '50%',
    background: theme.colors.gray1,
    width: '100%',
    height: 1,
  },
]);

const Separator: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Main>
      <Border />
      <span>{children}</span>
    </Main>
  );
};

export default Separator;
