import React, { useCallback } from 'react';
import { border, padding, rgba } from 'polished';

import styled from '@emotion/styled';
import { Radius, Grid, LineHeight, Weight } from 'renderer/utils/styled';

export enum ButtonSizes {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum ButtonModes {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  QUATERNARY,
  DANGER,
}

export type CommonProps = {
  active?: boolean;
  disabled?: boolean;
  href?: string;
  mode?: ButtonModes;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: (event: React.SyntheticEvent) => void;
  size?: ButtonSizes;
};

export type ComponentProps = {
  children?: string | React.ReactNode;
};

export type StyledProps = {
  label?: string | React.ReactNode;
  title?: string;
};

const IconSpacing = styled.span<StyledProps>(({ label }) => [
  {
    margin: Grid.M,
  },
  !label && {
    margin: Grid.NONE,
  },
]);

const StyledLink = styled.a<CommonProps & StyledProps>(
  ({ label, active, disabled, href, mode, size, onClick, theme }) => [
    {
      fontFamily: theme.fonts.sans,
      textAlign: 'center',
      textDecoration: 'none',
      textWrap: 'none',
      fontWeight: Weight.SEMIBOLD,
      lineHeight: LineHeight.NONE,
      transition: 'background 200ms ease-in-out, box-shadow 200ms ease-in-out, opacity 200ms ease-in-out',
      WebkitAppRegion: 'no-drag',
    },
    label && {
      borderRadius: Radius.S,
    },
    !label && {
      display: 'inline-block',
      borderRadius: Radius.ROUND,
    },

    (href || onClick !== undefined || onClick !== null) && {
      cursor: 'pointer',
    },

    size === ButtonSizes.SMALL && {
      fontSize: 10,
      ...padding(10),
    },
    size === ButtonSizes.MEDIUM && {
      fontSize: 12,
      ...padding(Grid.L),
    },

    size === ButtonSizes.LARGE && {
      fontSize: 16,
      ...padding(Grid.L),
    },

    mode === ButtonModes.PRIMARY && {
      display: 'block',
      background: theme.colors.brand,
      color: theme.colors.gray0,
      boxShadow: `0 0 10px ${rgba(theme.colors.black, 0.18)}`,

      ':hover': {
        boxShadow: `0 0 15px ${rgba(theme.colors.black, 0.28)}`,
      },
    },
    mode === ButtonModes.SECONDARY && {
      background: 'transparent',
      color: theme.colors.brand,
      ...border(2, 'solid', theme.colors.brand),

      ':hover': {
        background: rgba(theme.colors.brand, 0.05),
      },
    },
    mode === ButtonModes.TERTIARY && {
      padding: 0,
      color: theme.colors.brand,
      textOverflow: 'ellipsis',
    },
    mode === ButtonModes.QUATERNARY && {
      color: theme.colors.gray4,
    },
    !active && {
      opacity: 0.7,
      background: theme.colors.black,
    },
    mode === ButtonModes.DANGER && {
      background: theme.colors.red,
      color: theme.colors.gray0,
      boxShadow: `0 0 10px ${rgba(theme.colors.black, 0.18)}`,

      ':hover': {
        boxShadow: `0 0 15px ${rgba(theme.colors.black, 0.28)}`,
      },
    },
    disabled && {
      background: '#999',
      color: '#555',
      cursor: 'not-allowed', // TODO: why does this cursor not show up properly?
      opacity: 0.75,
    },
  ]
);

const Button: React.FC<ComponentProps & CommonProps & StyledProps> = ({
  active = true,
  children,
  disabled = false,
  leftIcon,
  rightIcon,
  href,
  size = ButtonSizes.MEDIUM,
  mode = ButtonModes.PRIMARY,
  title,
  onClick,
}) => {
  const handleOnClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (disabled) {
        event.preventDefault();
        return;
      }

      if (active && onClick) {
        onClick(event);
      }
    },
    [active, disabled, onClick]
  );

  return (
    <StyledLink
      label={children}
      title={title}
      active={active}
      aria-disabled={disabled ? 'true' : 'false'}
      disabled={disabled}
      href={href}
      mode={mode}
      onClick={handleOnClick}
      size={size}
    >
      {leftIcon && <IconSpacing label={children}>{leftIcon}</IconSpacing>}
      {children}
      {rightIcon && <IconSpacing label={children}>{rightIcon}</IconSpacing>}
    </StyledLink>
  );
};

export default Button;
