import styled from '@emotion/styled';
import { Grid, Weight } from 'renderer/utils/styled';

type WrapperProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
export const Wrapper = styled.div<WrapperProps>(({ onClick }) => [
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    WebkitAppRegion: 'no-drag',
  },
  onClick && {
    cursor: 'pointer',
    opacity: '.90',

    '&:hover': {
      opacity: '1',
    },
  },
]);

export enum ProfileSize {
  TINY,
  SMALL,
  MEDIUM,
  LARGE,
}

type IndicatorProps = {
  onlinePresence?: boolean;
  size?: ProfileSize;
};
export const Indicator = styled.div<IndicatorProps>(({ theme, onlinePresence = false, size }) => [
  {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: 9999,
    transition: 'background 400ms ease-in-out',
  },
  onlinePresence && {
    background: theme.colors.green1,
  },
  !onlinePresence && {
    background: theme.colors.red,
  },

  size === ProfileSize.TINY && {
    width: 5,
    height: 5,
  },
  size === ProfileSize.SMALL && {
    width: 10,
    height: 10,
  },
  size === ProfileSize.MEDIUM && {
    width: 16,
    height: 16,
  },
  size === ProfileSize.LARGE && {
    width: 32,
    height: 32,
  },
]);

export const Name = styled.div(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    color: theme.colors.gray4,
    fontWeight: Weight.BLACK,
    paddingLeft: Grid.M,
    fontSize: 14,
  },
]);
type WithoutImageProps = {
  editable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  size?: ProfileSize;
};
export const WithoutImage = styled.div<WithoutImageProps>(
  ({ editable, theme, onClick, size = ProfileSize.MEDIUM }) => [
    {
      fontFamily: theme.fonts.sans,
      color: theme.colors.white,
      borderRadius: 9999,
      textAlign: 'center',
      background: theme.colors.brand,
      transition: 'opacity 200ms ease-in-out',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    onClick && {
      opacity: '.90',
      cursor: 'pointer',

      '&:hover': {
        opacity: '1',
      },
    },
    editable && {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    size === ProfileSize.TINY && {
      width: 24,
      height: 24,
      minHeight: 24,
      minWidth: 24,
      fontSize: 11,
      border: `1px solid ${theme.colors.gray0}`,
    },
    size === ProfileSize.SMALL && {
      width: 40,
      height: 40,
      minHeight: 40,
      minWidth: 40,
      fontSize: 14,
      border: `1px solid ${theme.colors.gray0}`,
    },
    size === ProfileSize.MEDIUM && {
      fontSize: 24,
      width: 80,
      height: 80,
      minHeight: 80,
      minWidth: 80,
      border: `3px solid ${theme.colors.gray0}`,
    },
    size === ProfileSize.LARGE && {
      fontSize: 24,
      width: 160,
      height: 160,
      minHeight: 160,
      minWidth: 160,
      border: `1px solid ${theme.colors.gray0}`,
    },
  ]
);

type WithImageProps = {
  editable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  size?: ProfileSize;
};
export const WithImage = styled.div<WithImageProps>(
  ({ editable, theme, onClick, size = ProfileSize.MEDIUM }) => [
    {
      position: 'relative',
      transition: 'opacity 200ms ease-in-out',
      borderRadius: 9999,

      img: {
        borderRadius: 9999,
        width: '100%',
        height: 'auto',
      },
    },
    onClick && {
      cursor: 'pointer',
      opacity: '.90',

      '&:hover': {
        opacity: '1',
      },
    },
    editable && {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    size === ProfileSize.TINY && {
      width: 24,
      height: 24,
      minHeight: 24,
      minWidth: 24,
      border: `1px solid ${theme.colors.gray0}`,
    },
    size === ProfileSize.SMALL && {
      width: 40,
      height: 40,
      minHeight: 40,
      minWidth: 40,
      border: `1px solid ${theme.colors.gray0}`,
    },
    size === ProfileSize.MEDIUM && {
      width: 80,
      height: 80,
      minHeight: 80,
      minWidth: 80,
      border: `3px solid ${theme.colors.gray0}`,
    },
    size === ProfileSize.LARGE && {
      width: 160,
      height: 160,
      minHeight: 160,
      minWidth: 160,
      border: `1px solid ${theme.colors.gray0}`,
    },
  ]
);

type ImageProps = {
  size?: ProfileSize;
};
export const Image = styled.img<ImageProps>(({ size = ProfileSize.MEDIUM }) => [
  size === ProfileSize.TINY && {
    width: 24,
    height: 24,
    minHeight: 24,
    minWidth: 24,
  },
  size === ProfileSize.SMALL && {
    width: 40,
    height: 40,
    minHeight: 40,
    minWidth: 40,
  },
  size === ProfileSize.MEDIUM && {
    width: 80,
    height: 80,
    minHeight: 80,
    minWidth: 80,
  },
  size === ProfileSize.LARGE && {
    width: 160,
    height: 160,
    minHeight: 160,
    minWidth: 160,
  },
]);
