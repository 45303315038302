import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { groupBy } from 'lodash';
import { Helmet } from 'react-helmet';

import { darkTheme } from 'renderer/utils/styled';

import * as s from './styles';

export const Main: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Main.displayName = 'main';

export const Right: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Right.displayName = 'right';

export const Notification: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Notification.displayName = 'notifications';

type ViewerProps = {
  version?: string;
  keystone?: React.ReactNode | null;
  children: React.ReactNode;
};

const Viewer: React.FC<ViewerProps> = ({ children }) => {
  const nodes = React.Children.toArray(children);

  const {
    main,
    right,
    notifications,
    // @ts-ignore
  } = groupBy(nodes, (node) => node.type.displayName);

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>Ultimate</title>
      </Helmet>
      <s.Wrap right={right?.length > 0}>
        <s.Main>{main}</s.Main>
        {right?.length > 0 && <s.Right>{right}</s.Right>}
      </s.Wrap>
      {notifications?.length > 0 && <s.Notifications>{notifications}</s.Notifications>}
    </ThemeProvider>
  );
};

export default Viewer;
