import React from 'react';
import { useParams } from 'react-router-dom';

import { Form, Formik } from 'formik';
import Text from 'renderer/components/inputs/Text';
import Hero, { Main, RightSidebar } from 'renderer/components/templates/Hero';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';
// import Button, {ButtonModes} from 'renderer/system/atoms/Button';

import Button from 'renderer/system/atoms/Button';
import Body from 'renderer/system/typography/Body';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import { useLocalRef, useSessionRef } from 'renderer/hooks/useCacheState';
import HiddenSubmit from 'renderer/components/inputs/HiddenSubmit';
import { useNotificationManager } from 'renderer/hooks/useNotification';

import { useResetPasswordMutation } from './queries';
import Title2 from 'renderer/system/typography/Title2';

type ResetPasswordForm = {
  newPassword: string;
  confirmPassword: string;
};

const resetInitialValues: ResetPasswordForm = {
  newPassword: '',
  confirmPassword: '',
};

const ResetPasswordManager: React.FC = () => {
  const { resetToken } = useParams();
  const sendNotification = useNotificationManager();
  const [, setAccessToken] = useLocalRef('settings.access-token', null);
  const [postAuth, setPostAuth] = useSessionRef<string | null>('settings.post-auth', null);
  const [createResetPassword] = useResetPasswordMutation();

  const handleSubmitResetPassword = async ({ newPassword, confirmPassword }: ResetPasswordForm) => {
    if (newPassword !== confirmPassword) {
      sendNotification('Passwords do not match', 5000);
      return;
    }
    const result = await createResetPassword({
      variables: { resetToken, newPassword },
    });
    setAccessToken(result.data?.resetPassword?.accessToken);

    if (postAuth) {
      setPostAuth(null);
      window.location.pathname = postAuth;
    } else {
      window.location.pathname = '/game/new';
    }
  };

  return (
    <Hero backgroundImage={SkyImage}>
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        <Formik<ResetPasswordForm> initialValues={resetInitialValues} onSubmit={handleSubmitResetPassword}>
          {({ submitForm }) => (
            <Form>
              <Section horizontal={0} vertical={2} mode={SectionMode.Focus}>
                <Title2>Welcome to Ultimate Engine</Title2>
              </Section>
              <Section horizontal={2} vertical={2}>
                <Body>
                  <p>Use the form below to reset your password:</p>
                </Body>
              </Section>
              <Section horizontal={2} vertical={2}>
                <Text name="newPassword" label="Password" type="password" />
                <Text name="confirmPassword" label="Confirm Password" type="password" />
              </Section>
              <Button onClick={submitForm}>Reset</Button>
              <HiddenSubmit />
            </Form>
          )}
        </Formik>
      </RightSidebar>
    </Hero>
  );
};

export default ResetPasswordManager;
