import React, { useCallback, useRef } from 'react';
import { useField } from 'formik';

import { ErrorMessage, Row, Label, Input } from './styles';
import Warning from 'renderer/icons/Warning';

type PublicProps<_T> = {
  name: HTMLSelectElement['name'];
  label?: string;
  onBlur?: (value: unknown) => void;
  onChange?: (name: string, value: unknown) => void;
  validate?: (value: any) => boolean;
  children: React.ReactNode;
};

export type PrivateProps<T> = PublicProps<T> & {
  displayLabel?: boolean;
  tabIndex?: HTMLSelectElement['tabIndex'];
  error?: string | null;
  // TODO: how to make this a better type for onBlur? There is no React.BlurEventHandler :(
  onBlur?: React.EventHandler<React.SyntheticEvent<HTMLSelectElement>>;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  valid: boolean;
  value: string;
};

export function Private<T>({
  children,
  label,
  displayLabel = true,
  onChange,
  valid,
  value,
  error,
}: PrivateProps<T>) {
  return (
    <Row>
      {label && displayLabel && (
        <Label>
          {label} {!valid && <Warning></Warning>}
        </Label>
      )}
      <Input onChange={onChange} value={value}>
        {children}
      </Input>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Row>
  );
}

function Public<T = string>({ name, label, onBlur, onChange, validate, children }: PublicProps<T>) {
  const [field, meta, helper] = useField(name);
  const validData = useRef(true);

  const handleOnBlur = useCallback(
    (event: any) => {
      const value = (() => {
        try {
          return JSON.parse(event.target.value);
        } catch (e) {
          return event.target.value;
        }
      })();

      field.onBlur(event);

      if (onBlur) {
        onBlur(value);
      }
    },
    [field, onBlur]
  );

  const handleOnChange = useCallback(
    (event: any) => {
      const value = (() => {
        try {
          return JSON.parse(event.target.value);
        } catch (e) {
          return event.target.value;
        }
      })();
      if (onChange) onChange(name, value);
      else helper.setValue(event.target.value === '' ? null : value);
      validData.current = validate?.(value) ?? true;
    },
    [helper, onChange, name, validate]
  );

  const value = ((): string => {
    if (typeof field.value === 'string' && (field.value === '' || field.value === 'null')) {
      return '';
    } else if (typeof field.value === 'object' && field.value === null) {
      return '';
    } else if (typeof field.value === 'object' && field.value !== null && field.value !== undefined) {
      return JSON.stringify(field.value);
    } else if (typeof field.value === 'undefined' && field.value === undefined) {
      return '';
    } else if (typeof field.value === 'string') {
      return field.value;
    } else {
      return Object.keys(field.value)[0];
    }
  })();

  validData.current = validate?.(value) ?? true;

  return (
    <Private<T>
      label={label}
      error={meta.touched && meta.error ? meta.error : null}
      name={name}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      valid={validData.current}
      value={value}
    >
      {children}
    </Private>
  );
}

export default Public;
