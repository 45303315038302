import { border, padding, rgba } from 'polished';
import { Grid, Weight } from 'renderer/utils/styled';

import styled from '@emotion/styled';

export const Wrapper = styled.div(({ theme }) => [
  {
    background: rgba(theme.colors.white, 0.25),
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(3px)',
    zIndex: 10,
  },
]);

export type MainProps = {
  width?: number;
};

export const Main = styled.div<MainProps>(({ theme, width = 700 }) => [
  {
    width,
    background: theme.colors.black,
    color: theme.colors.black,
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: 4,
  },
]);

export const Content = styled.div(() => [
  {
    padding: 15,
  },
]);

export const HeaderLine = styled.div(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    background: theme.colors.black,
    fontWeight: Weight.BOLD,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    color: theme.colors.white,
    ...padding(Grid.M),
    ...border('bottom', 1, 'solid', '#2C2C2C'),
  },
]);

export const Close = styled.a(({ theme }) => [
  {
    color: theme.colors.white,
    cursor: 'pointer',
    width: 13,
    height: 13,
    display: 'block',
  },
]);
