import React from 'react';
import styled from '@emotion/styled';

const Svg = styled.svg(() => ({
  cursor: 'inherit',
  pointerEvents: 'none',
}));

const Path = styled.path(() => ({
  fill: 'currentcolor',
}));

const User: React.FC = () => (
  <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6ZM0 12C1.63335 5.33333 10.3666 5.33333 12 12H0Z"
      fill="#828282"
    />
  </Svg>
);

export default User;
