export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  UUID: any;
};

export type Access = {
  __typename?: 'Access';
  accessToken: Scalars['UUID'];
};

export type Allocation = {
  __typename?: 'Allocation';
  gameServerName: Scalars['String'];
  tcp: Scalars['String'];
  udp: Scalars['String'];
};

export type Auth = {
  __typename?: 'Auth';
  email?: Maybe<Scalars['String']>;
  games: Array<Game>;
  id: Scalars['ID'];
  invitation?: Maybe<Invitation>;
  invitationInfo: InvitationInfo;
  invitationInfoBySessionId: InvitationInfo;
  invitationInfoByShortname: InvitationInfo;
  role?: Maybe<Role>;
  user: User;
  username?: Maybe<Scalars['String']>;
};


export type AuthInvitationArgs = {
  gameId: Scalars['UUID'];
};


export type AuthInvitationInfoArgs = {
  gameId: Scalars['UUID'];
};


export type AuthInvitationInfoBySessionIdArgs = {
  sessionId: Scalars['UUID'];
};


export type AuthInvitationInfoByShortnameArgs = {
  shortname: Scalars['String'];
};


export type AuthRoleArgs = {
  gameId: Scalars['UUID'];
};

export type Branch = {
  __typename?: 'Branch';
  createdAt: Scalars['DateTime'];
  game: Game;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Comment = {
  __typename?: 'Comment';
  author: Auth;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  thread: Thread;
};

export type CommentMeta = {
  __typename?: 'CommentMeta';
  count: Scalars['Int'];
  latest: Comment;
  profiles: Array<Auth>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateComment = {
  content: Scalars['String'];
  threadId: Scalars['UUID'];
};

export type CreateGame = {
  companyId: Scalars['String'];
  gameName: Scalars['String'];
  shortname: Scalars['String'];
};

export type CreateThread = {
  content: Scalars['String'];
  gameId: Scalars['UUID'];
  levelId: Scalars['UUID'];
  position: PositionInput;
};

export enum Environment {
  Local = 'LOCAL',
  Production = 'PRODUCTION',
  Staging = 'STAGING'
}

export type Game = {
  __typename?: 'Game';
  branches: Array<Branch>;
  company: Company;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shortname: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Invitation = {
  __typename?: 'Invitation';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  game: Game;
  hasEmail: Scalars['Boolean'];
  id: Scalars['ID'];
  invitationToken: Scalars['UUID'];
  invitee: Auth;
  inviter: Auth;
  postInvite?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export type InvitationInfo = {
  __typename?: 'InvitationInfo';
  allowed?: Maybe<Scalars['Int']>;
  invitationToken: Scalars['UUID'];
  used: Scalars['Int'];
};

export type Login = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  acceptInvitationWithChannel: Access;
  acceptInvitationWithoutChannel: Access;
  createComment: Comment;
  createGame: Game;
  createGameWithPermission: Game;
  createInvitation: Invitation;
  createResetPassword: Scalars['Boolean'];
  createSession: Session;
  createSessionComment: SessionComment;
  createSessionWithBranch: Session;
  createThread: Thread;
  login: Access;
  resendInvitation: Scalars['Boolean'];
  resetPassword: Access;
  setFavoritePlaySession: PlaySessionFavorite;
  signup: User;
};


export type MutationRootAcceptInvitationWithChannelArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationToken: Scalars['UUID'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRootAcceptInvitationWithoutChannelArgs = {
  firstName: Scalars['String'];
  invitationToken: Scalars['UUID'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRootCreateCommentArgs = {
  create: CreateComment;
};


export type MutationRootCreateGameArgs = {
  createGame: CreateGame;
};


export type MutationRootCreateGameWithPermissionArgs = {
  authAccess: Scalars['UUID'];
  companyId: Scalars['UUID'];
  gameName: Scalars['String'];
  shortname: Scalars['String'];
};


export type MutationRootCreateInvitationArgs = {
  email: Scalars['String'];
  gameId: Scalars['String'];
  postInvite?: InputMaybe<Scalars['String']>;
  role: Role;
};


export type MutationRootCreateResetPasswordArgs = {
  authId: Scalars['String'];
};


export type MutationRootCreateSessionArgs = {
  shortname: Scalars['String'];
};


export type MutationRootCreateSessionCommentArgs = {
  content: Scalars['String'];
  offset: Scalars['Float'];
  sessionId: Scalars['UUID'];
};


export type MutationRootCreateSessionWithBranchArgs = {
  branchId: Scalars['UUID'];
};


export type MutationRootCreateThreadArgs = {
  create: CreateThread;
};


export type MutationRootLoginArgs = {
  login: Login;
};


export type MutationRootResendInvitationArgs = {
  authId: Scalars['UUID'];
};


export type MutationRootResetPasswordArgs = {
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
};


export type MutationRootSetFavoritePlaySessionArgs = {
  favorite: Scalars['Boolean'];
  playSessionId: Scalars['UUID'];
};


export type MutationRootSignupArgs = {
  signup: Signup;
};

export type PlaySession = {
  __typename?: 'PlaySession';
  createdAt: Scalars['DateTime'];
  favorite?: Maybe<PlaySessionFavorite>;
  game: Game;
  id: Scalars['ID'];
  sessionId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  videoUrl: Scalars['String'];
};

export type PlaySessionFavorite = {
  __typename?: 'PlaySessionFavorite';
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
};

export enum PlaySessionFilter {
  All = 'ALL',
  Favorites = 'FAVORITES',
  NotFavorites = 'NOT_FAVORITES'
}

export type Position = {
  __typename?: 'Position';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type PositionInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  auth?: Maybe<Auth>;
  game?: Maybe<Game>;
  gameByShortname?: Maybe<Game>;
  games: Array<Game>;
  invitation?: Maybe<Invitation>;
  invitations: Array<Invitation>;
  playSession?: Maybe<PlaySession>;
  session?: Maybe<Session>;
  user?: Maybe<User>;
};


export type QueryRootGameArgs = {
  gameId: Scalars['UUID'];
};


export type QueryRootGameByShortnameArgs = {
  shortname: Scalars['String'];
};


export type QueryRootInvitationArgs = {
  invitationToken: Scalars['String'];
};


export type QueryRootPlaySessionArgs = {
  id: Scalars['String'];
};


export type QueryRootSessionArgs = {
  sessionId: Scalars['UUID'];
};

export enum Role {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Guest = 'GUEST',
  User = 'USER'
}

export type Session = {
  __typename?: 'Session';
  allocation?: Maybe<Allocation>;
  id: Scalars['ID'];
  version: Version;
};

export type SessionComment = {
  __typename?: 'SessionComment';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  offset: Scalars['Float'];
  sessionId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type Signup = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gameName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  shortname: Scalars['String'];
  username: Scalars['String'];
};

export type SubscriptionRoot = {
  __typename?: 'SubscriptionRoot';
  getCommentUpdates: Comment;
  getCommentsForGame: Array<Comment>;
  getCommentsForThread: ThreadsMeta;
  getPlaySessions: Array<PlaySession>;
  getSessionComments: Array<SessionComment>;
  getThreads: Array<Thread>;
};


export type SubscriptionRootGetCommentsForGameArgs = {
  gameId: Scalars['UUID'];
};


export type SubscriptionRootGetCommentsForThreadArgs = {
  threadId: Scalars['UUID'];
};


export type SubscriptionRootGetPlaySessionsArgs = {
  filter: PlaySessionFilter;
  gameId: Scalars['UUID'];
  pageIndex: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type SubscriptionRootGetSessionCommentsArgs = {
  sessionId: Scalars['UUID'];
};


export type SubscriptionRootGetThreadsArgs = {
  gameId: Scalars['UUID'];
  levelId: Scalars['UUID'];
};

export type Thread = {
  __typename?: 'Thread';
  author: Auth;
  comments: Array<Comment>;
  id: Scalars['ID'];
  meta: CommentMeta;
  position: Position;
  title: Scalars['String'];
};

export type ThreadsMeta = {
  __typename?: 'ThreadsMeta';
  comments: Array<Comment>;
  thread: Thread;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type Version = {
  __typename?: 'Version';
  branch: Branch;
  clientStorage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  environment: Environment;
  executable?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sha: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};
