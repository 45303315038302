import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Form, Formik } from 'formik';
import Text from 'renderer/components/inputs/Text';
import Hero, { Main, RightSidebar } from 'renderer/components/templates/Hero';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';
// import Button, {ButtonModes} from 'renderer/system/atoms/Button';

import Button from 'renderer/system/atoms/Button';
import Body from 'renderer/system/typography/Body';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import Profile from 'renderer/system/molecules/Profile';
import HiddenSubmit from 'renderer/components/inputs/HiddenSubmit';
import { useLocalNotifications, useNotificationManager } from 'renderer/hooks/useNotification';
import { useLocalRef, useSessionRef } from 'renderer/hooks/useCacheState';

import {
  GetInvitationQueryHookResult,
  useAcceptInvitationWithChannelMutation,
  useAcceptInvitationWithoutChannelMutation,
  useGetInvitationQuery,
} from './queries';
import Title2 from 'renderer/system/typography/Title2';
import Row from 'renderer/system/misc/Row';
import { Role } from 'types';
import Alert, { BannerType } from 'renderer/system/atoms/Alert';

type PostAuth = {
  type: 'game';
  url: string;
};

type AdminInvitationForm = {
  username: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
};

type UserInvitationForm = {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  firstName: string;
  lastName: string;
};

const adminLoginInitialValues: AdminInvitationForm = {
  username: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
};

const userLoginInitialValues: UserInvitationForm = {
  username: '',
  password: '',
  email: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
};

type AdminInvitationManagerProps = {
  invitationQuery: GetInvitationQueryHookResult;
};

const AdminInvitationManager: React.FC<AdminInvitationManagerProps> = ({ invitationQuery }) => {
  const { invitationToken } = useParams();
  const sendNotification = useNotificationManager();
  const [, setAccessToken] = useLocalRef('settings.access-token', null);
  const [acceptInvitation] = useAcceptInvitationWithoutChannelMutation();

  const handleSubmitInvitation = async ({
    username,
    password,
    confirmPassword,
    firstName,
    lastName,
  }: AdminInvitationForm) => {
    if (!password || !username || !firstName || !lastName) {
      sendNotification('Please fill all required fields', 5000);
      return;
    }
    if (password !== confirmPassword) {
      sendNotification('Passwords do not match', 5000);
      return;
    }
    const result = await acceptInvitation({
      variables: { invitationToken, username, password, firstName, lastName },
    });
    setAccessToken(result.data?.acceptInvitationWithoutChannel?.accessToken);
    window.location.pathname = '/downloads/';
  };
  const invitation = invitationQuery.data?.invitation ?? null;
  const inviter = invitation?.inviter ?? null;
  const game = invitation?.game ?? null;

  if (invitationQuery.loading) {
    return (
      <Hero
        backgroundImage={SkyImage}
        keystone={inviter && <Profile firstName={inviter.user.firstName} lastName={inviter.user.lastName} />}
      >
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading...</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  return (
    <Hero
      backgroundImage={SkyImage}
      keystone={inviter && <Profile firstName={inviter.user.firstName} lastName={inviter.user.lastName} />}
    >
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        {invitation && inviter && game ? (
          <Formik<AdminInvitationForm>
            initialValues={adminLoginInitialValues}
            onSubmit={handleSubmitInvitation}
          >
            {({ submitForm }) => (
              <Form>
                <Section horizontal={0} vertical={2} mode={SectionMode.Focus}>
                  <Title2>Welcome to Ultimate Engine</Title2>
                </Section>
                <Section horizontal={2} vertical={2}>
                  <Body>
                    <p>
                      {inviter.user.firstName} {inviter.user.lastName} has invited you to join them in
                      building the game <strong>{game.name}</strong>.
                    </p>
                    <p>Sign up using the form below:</p>
                  </Body>
                </Section>
                <Section horizontal={2} vertical={2}>
                  <Row>
                    <Text name="firstName" label="First name*" />
                    <Text name="lastName" label="Last name*" />
                  </Row>
                  <Text name="username" label="Username*" />
                  <Text name="password" label="Password*" type="password" />
                  <Text name="confirmPassword" label="Confirm Password*" type="password" />
                </Section>
                <Button onClick={submitForm}>Sign up!</Button>
                <HiddenSubmit />
              </Form>
            )}
          </Formik>
        ) : (
          <Body>Invitation not found</Body>
        )}
      </RightSidebar>
    </Hero>
  );
};

type UserInvitationManagerProps = {
  invitationQuery: GetInvitationQueryHookResult;
};

const UserInvitationManager: React.FC<UserInvitationManagerProps> = ({ invitationQuery }) => {
  const [alerts, notify] = useLocalNotifications();
  const { invitationToken } = useParams();
  const [postInvite, setPostInvite] = useSessionRef<PostAuth | null>('settings.post-invite', null);
  const [, setAccessToken] = useLocalRef('settings.access-token', null);
  const [acceptInvitationWithChannel] = useAcceptInvitationWithChannelMutation();
  const [acceptInvitationWithoutChannel] = useAcceptInvitationWithoutChannelMutation();

  const invitation = invitationQuery.data?.invitation ?? null;
  const inviter = invitation?.inviter ?? null;
  const game = invitation?.game ?? null;

  useEffect(() => {
    if (!invitation?.postInvite) {
      return;
    }
    setPostInvite({ type: 'game', url: invitation.postInvite });
  }, [invitation?.postInvite, setPostInvite]);

  const handleSubmitInvitation = async ({
    username,
    password,
    confirmPassword,
    email,
    firstName,
    lastName,
  }: UserInvitationForm) => {
    try {
      if (!password || !username || !firstName || !lastName) {
        notify('Please fill all required fields', 5000);
        return;
      }
      if (password !== confirmPassword) {
        notify('Passwords do not match', 5000);
        return;
      }
      if (invitation?.hasEmail) {
        const result = await acceptInvitationWithoutChannel({
          variables: { invitationToken, username, password, firstName, lastName },
        });
        setAccessToken(result.data?.acceptInvitationWithoutChannel?.accessToken);
      } else {
        const result = await acceptInvitationWithChannel({
          variables: { invitationToken, username, password, email, firstName, lastName },
        });
        setAccessToken(result.data?.acceptInvitationWithChannel?.accessToken);
      }

      if (postInvite) {
        const post = postInvite.url;
        setPostInvite(null);
        window.location.pathname = post;
      } else {
        window.location.pathname = '/';
      }
    } catch (e) {
      // @ts-ignore
      notify(e.message, 5000);
    }
  };

  if (invitationQuery.loading) {
    return (
      <Hero
        backgroundImage={SkyImage}
        keystone={inviter && <Profile firstName={inviter.user.firstName} lastName={inviter.user.lastName} />}
      >
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading...</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  return (
    <Hero
      backgroundImage={SkyImage}
      keystone={inviter && <Profile firstName={inviter.user.firstName} lastName={inviter.user.lastName} />}
    >
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        {invitation && inviter && game ? (
          <Formik<UserInvitationForm>
            initialValues={userLoginInitialValues}
            onSubmit={handleSubmitInvitation}
          >
            {({ submitForm }) => (
              <Form>
                <Section horizontal={0} vertical={2} mode={SectionMode.Focus}>
                  <Title2>Welcome to {game.name}</Title2>
                </Section>
                {alerts.map((alert) => (
                  <Alert bannerType={BannerType.FAILURE} key={alert.id}>
                    {alert.content}
                  </Alert>
                ))}
                <Section horizontal={2} vertical={2}>
                  <Body>
                    <p>
                      {inviter.user.firstName} {inviter.user.lastName} has invited you to join them and play
                      the game <strong>{game.name}</strong> together!
                    </p>
                    <p>Sign up using the form below:</p>
                  </Body>
                </Section>
                <Section horizontal={2} vertical={2}>
                  <Row>
                    <Text name="firstName" label="First name*" />
                    <Text name="lastName" label="Last name*" />
                  </Row>
                  {!invitation.hasEmail && <Text name="email" label="E-mail*" />}
                  <Text name="username" label="Username*" />
                  <Row>
                    <Text name="password" label="Password*" type="password" />
                    <Text name="confirmPassword" label="Confirm Password*" type="password" />
                  </Row>
                </Section>
                <Button onClick={submitForm}>Sign up!</Button>
                <HiddenSubmit />
              </Form>
            )}
          </Formik>
        ) : (
          <Body>Invitation not found</Body>
        )}
      </RightSidebar>
    </Hero>
  );
};
const InvitationManager: React.FC = () => {
  const { invitationToken } = useParams();
  const navigate = useNavigate();
  const invitationQuery = useGetInvitationQuery({
    variables: { invitationToken: invitationToken ?? '' },
    skip: !invitationToken,
  });

  const invitation = invitationQuery.data?.invitation ?? null;
  const role = invitation?.role ?? Role.User;

  useEffect(() => {
    if (!invitation) return;
    if (invitation.invitationToken == invitationToken) return;

    // this invitation token is actually a user token. Now that we have
    // a proper token, let's switch.
    navigate(`/auth/invitation/${invitation.invitationToken}`, { replace: true });
  }, [invitation, navigate, invitationToken]);

  return role === Role.Admin || role === Role.Editor ? (
    <AdminInvitationManager invitationQuery={invitationQuery} />
  ) : (
    <UserInvitationManager invitationQuery={invitationQuery} />
  );
};

export default InvitationManager;
