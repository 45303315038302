import React, { useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import * as uuid from 'uuid';

// this could be simpler, or, rather, not exist at all.
type Dialog = {
  isOpen: boolean;
};

type Dialogs = { [id: string]: Dialog };

type Notification = {
  content: string;
  id: string;
};

export class UiStore {
  notifications: Notification[];
  dialogs: Dialogs;

  constructor() {
    this.notifications = [];
    this.dialogs = {};
    makeAutoObservable(this);
  }

  notify(content: string, timeout: number) {
    const id = uuid.v4();
    this.notifications.push({
      id,
      content,
    });

    setTimeout(() => {
      this.removeNotification(id);
    }, timeout);
  }

  openDialog(id: string) {
    this.dialogs[id] = {
      isOpen: true,
    };
  }

  closeDialog(id: string) {
    delete this.dialogs[id];
  }

  isDialogOpen(id: string): boolean {
    return !!this.dialogs[id];
  }

  removeNotification(id: string) {
    const filtered = this.notifications.filter((item) => item.id !== id);
    this.notifications = filtered;
  }

  get openDialogs(): Dialog[] {
    return Object.values(this.dialogs);
  }
}

const Context = React.createContext<UiStore | null>(null);

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Context.Provider value={new UiStore()}>{children}</Context.Provider>;
};

export const useUi = (): UiStore => {
  const data = useContext(Context);

  if (!data) throw Error("You shouldn't be using this data outside of the provider");

  return data;
};
