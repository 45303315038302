import React from 'react';
import styled from '@emotion/styled';
import { Weight } from 'renderer/utils/styled';

export enum TitleMode {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}
type Title2Props = ModeProps & {
  children: string | React.ReactNode;
};

type ModeProps = {
  mode?: TitleMode;
};
const Copy = styled.div<ModeProps>(({ theme, mode = TitleMode.PRIMARY }) => [
  {
    fontSize: 24,
    lineHeight: '1.3em',
    fontWeight: Weight.BOLD,
    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
  },
  mode === TitleMode.PRIMARY && {
    color: theme.colors.white,
  },
  mode === TitleMode.SECONDARY && {
    color: theme.colors.gray4,
  },
  mode === TitleMode.TERTIARY && {
    color: theme.colors.brand,
  },
]);

const Title2: React.FC<Title2Props> = ({ children, mode = TitleMode.PRIMARY }) => {
  return <Copy mode={mode}>{children}</Copy>;
};

export default Title2;
