import styled from '@emotion/styled';
import { padding, rgba } from 'polished';
import { Weight, Radius, Grid, LineHeight } from 'renderer/utils/styled';

type MainProps = {
  onClick?: () => void;
};

export const Main = styled.div<MainProps>(({ onClick }) => [
  {
    display: 'flex',
    gap: Grid.M,
    borderRadius: Radius.L,
    transition: 'color 300ms ease-in-out,background 300ms ease-in-out',
    alignItems: 'flex-start',
  },
  onClick && {
    cursor: 'pointer',
    ...padding(Grid.M),
    '&:hover': {
      background: rgba(255, 255, 255, 0.1),
    },
  },
]);

export const Wrapper = styled.div(() => [
  {
    width: '100%',
  },
]);

export const Image = styled.img(() => [
  {
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    borderRadius: Radius.M + Grid.M,

    ...padding(Grid.M, Grid.M, 0, Grid.M),
  },
]);

export const Content = styled.div(({ theme }) => []);

export const Time = styled.div(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    color: theme.colors.gray4,
    fontSize: 11,
  },
]);

export const Section = styled.div(() => [
  {
    display: 'flex',
    ...padding(Grid.M, 0),
  },
]);

export const Thread = styled.div(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    fontSize: 11,
    color: theme.colors.gray3,
    ...padding(0, Grid.M),
  },
]);

export const Title = styled.div(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    fontSize: 13,
    lineHeight: LineHeight.SINGLE,
    color: theme.colors.white,
    fontWeight: Weight.BOLD,
    ...padding(0, Grid.M, 0, 0),
  },
]);

export const Body = styled.div(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    fontWeight: Weight.REGULAR,
    lineHeight: LineHeight.NORMAL,
    color: theme.colors.white,
    fontSize: 12,
  },
]);

export const Footer = styled.div(({ theme }) => [
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
]);
