import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInvitationQueryVariables = Types.Exact<{
  invitationToken: Types.Scalars['String'];
}>;


export type GetInvitationQuery = { __typename?: 'QueryRoot', invitation?: { __typename?: 'Invitation', id: string, createdAt: any, role?: Types.Role | null, invitationToken: any, postInvite?: string | null, hasEmail: boolean, game: { __typename?: 'Game', id: string, name: string }, inviter: { __typename?: 'Auth', id: string, user: { __typename?: 'User', firstName: string, lastName: string } } } | null };

export type AcceptInvitationWithChannelMutationVariables = Types.Exact<{
  invitationToken: Types.Scalars['UUID'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type AcceptInvitationWithChannelMutation = { __typename?: 'MutationRoot', acceptInvitationWithChannel: { __typename?: 'Access', accessToken: any } };

export type AcceptInvitationWithoutChannelMutationVariables = Types.Exact<{
  invitationToken: Types.Scalars['UUID'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type AcceptInvitationWithoutChannelMutation = { __typename?: 'MutationRoot', acceptInvitationWithoutChannel: { __typename?: 'Access', accessToken: any } };


export const GetInvitationDocument = gql`
    query GetInvitation($invitationToken: String!) {
  invitation(invitationToken: $invitationToken) {
    id
    createdAt
    role
    invitationToken
    postInvite
    hasEmail
    game {
      id
      name
    }
    inviter {
      id
      user {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const AcceptInvitationWithChannelDocument = gql`
    mutation AcceptInvitationWithChannel($invitationToken: UUID!, $firstName: String!, $lastName: String!, $email: String!, $username: String!, $password: String!) {
  acceptInvitationWithChannel(
    invitationToken: $invitationToken
    firstName: $firstName
    lastName: $lastName
    email: $email
    username: $username
    password: $password
  ) {
    accessToken
  }
}
    `;
export type AcceptInvitationWithChannelMutationFn = Apollo.MutationFunction<AcceptInvitationWithChannelMutation, AcceptInvitationWithChannelMutationVariables>;

/**
 * __useAcceptInvitationWithChannelMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationWithChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationWithChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationWithChannelMutation, { data, loading, error }] = useAcceptInvitationWithChannelMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAcceptInvitationWithChannelMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationWithChannelMutation, AcceptInvitationWithChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationWithChannelMutation, AcceptInvitationWithChannelMutationVariables>(AcceptInvitationWithChannelDocument, options);
      }
export type AcceptInvitationWithChannelMutationHookResult = ReturnType<typeof useAcceptInvitationWithChannelMutation>;
export type AcceptInvitationWithChannelMutationResult = Apollo.MutationResult<AcceptInvitationWithChannelMutation>;
export type AcceptInvitationWithChannelMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationWithChannelMutation, AcceptInvitationWithChannelMutationVariables>;
export const AcceptInvitationWithoutChannelDocument = gql`
    mutation AcceptInvitationWithoutChannel($invitationToken: UUID!, $firstName: String!, $lastName: String!, $username: String!, $password: String!) {
  acceptInvitationWithoutChannel(
    invitationToken: $invitationToken
    firstName: $firstName
    lastName: $lastName
    username: $username
    password: $password
  ) {
    accessToken
  }
}
    `;
export type AcceptInvitationWithoutChannelMutationFn = Apollo.MutationFunction<AcceptInvitationWithoutChannelMutation, AcceptInvitationWithoutChannelMutationVariables>;

/**
 * __useAcceptInvitationWithoutChannelMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationWithoutChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationWithoutChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationWithoutChannelMutation, { data, loading, error }] = useAcceptInvitationWithoutChannelMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAcceptInvitationWithoutChannelMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationWithoutChannelMutation, AcceptInvitationWithoutChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationWithoutChannelMutation, AcceptInvitationWithoutChannelMutationVariables>(AcceptInvitationWithoutChannelDocument, options);
      }
export type AcceptInvitationWithoutChannelMutationHookResult = ReturnType<typeof useAcceptInvitationWithoutChannelMutation>;
export type AcceptInvitationWithoutChannelMutationResult = Apollo.MutationResult<AcceptInvitationWithoutChannelMutation>;
export type AcceptInvitationWithoutChannelMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationWithoutChannelMutation, AcceptInvitationWithoutChannelMutationVariables>;