import React from 'react';
import styled from '@emotion/styled';

import { padding } from 'polished';

export enum SectionMode {
  Normal,
  Focus,
  LoadGame,
}

type MainProps = {
  children: React.ReactNode;
  horizontal?: number;
  vertical?: number;
  mode?: SectionMode;
};

export const Main = styled.div<MainProps>(({ horizontal = 0, vertical = 0, mode = SectionMode.Normal }) => [
  {
    ...padding(4 * vertical, 4 * horizontal),
    overflow: 'auto',
  },
  mode === SectionMode.Normal && {
    textAlign: 'left',
  },
  mode === SectionMode.Focus && {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mode === SectionMode.LoadGame && {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    overflow: 'hidden',
  },
]);

const Section: React.FC<MainProps> = ({
  children,
  horizontal = 0,
  vertical = 0,
  mode = SectionMode.Normal,
}) => {
  return (
    <Main horizontal={horizontal} vertical={vertical} mode={mode}>
      {children}
    </Main>
  );
};

export default Section;
