import React from 'react';
import styled from '@emotion/styled';

export enum BodyMode {
  PRIMARY,
  SECONDARY,
}
type BodyProps = ModeProps & {
  children: string | React.ReactNode;
};

type ModeProps = {
  mode?: BodyMode;
};
const Copy = styled.div<ModeProps>(({ theme, mode = BodyMode.PRIMARY }) => [
  {
    fontSize: 12,
    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
    textOverflow: 'ellipsis',
  },
  mode === BodyMode.PRIMARY && {
    color: theme.colors.white,
  },
  mode === BodyMode.SECONDARY && {
    color: theme.colors.gray4,
  },
]);

const Body: React.FC<BodyProps> = ({ children, mode = BodyMode.PRIMARY }) => {
  return <Copy mode={mode}>{children}</Copy>;
};

export default Body;
