import React from 'react';
import { createRoot } from 'react-dom/client';
import * as sentry from 'renderer/utils/errors';
import * as analytics from 'renderer/utils/analytics';
import { Platform } from 'renderer/components/misc/Platform';

const init = async () => {
  try {
    const sessionUrl = await analytics.init();
    if (sessionUrl) sentry.init(sessionUrl);
  } catch (e) {
    console.error(e);
  }
};
init();

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<Platform />);
}
