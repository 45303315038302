import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Controlled as Text } from 'renderer/components/inputs/Text';
import Textarea from 'renderer/components/inputs/Textarea';
import Select from 'renderer/components/inputs/Select';
import Caption, { CaptionMode } from 'renderer/system/typography/Caption';
import Body from 'renderer/system/typography/Body';
import HiddenSubmit from 'renderer/components/inputs/HiddenSubmit';
import { useNotificationManager } from 'renderer/hooks/useNotification';
import { relative } from 'renderer/utils/datetime';

import * as s from './styles';
import { Role } from 'types';
import { useInvitationsQuery, useInviteMutation } from './queries';
import Section from 'renderer/components/experience/Section';
import Eyebrow from 'renderer/system/typography/Eyebrow';
import Row, { Spacing } from 'renderer/system/misc/Row';
import Button from 'renderer/system/atoms/Button';

function isElectron(): boolean {
  return navigator.userAgent.indexOf('Electron') !== -1;
}

type InvitationForm = {
  emails: string;
  role: Role;
};

type InvitationManagerProps = {
  gameId?: string;
};
const InvitationManager: React.FC<InvitationManagerProps> = ({ gameId }) => {
  const sendNotification = useNotificationManager();
  const invitationQuery = useInvitationsQuery({
    variables: { gameId },
  });
  const invitations = invitationQuery.data?.invitations ?? [];
  const [invite] = useInviteMutation();
  const role = invitationQuery.data?.auth?.role ?? Role.User;
  const info = invitationQuery.data?.auth?.invitationInfo ?? {
    used: 0,
    allowed: undefined,
    invitationToken: 'unknown',
  };

  const personalInviteUrl = `${window.location.href}?invite=${info.invitationToken}`;

  const handleSubmit = async ({ emails, role }: InvitationForm, formik: FormikHelpers<InvitationForm>) => {
    if (!gameId) {
      sendNotification('Cannot invite user without a game selected', 5000);
      return;
    }

    const invites = emails
      .split(',')
      .map((email) => email.trim())
      .map(async (email) => {
        let postInvite = null;
        if (!isElectron()) {
          postInvite = window.location.pathname;
        }
        await invite({
          variables: { email, gameId, role, postInvite },
        });
      });

    await Promise.all(invites);
    formik.resetForm();
    invitationQuery.refetch();
  };

  const initialValues: InvitationForm = {
    emails: '',
    role: Role.User,
  };

  return (
    <s.Layout>
      <s.List>
        {invitations.map((invitation) => (
          <Section horizontal={0} key={invitation.id}>
            <Body>{invitation.invitee.email ?? 'unknown email'}</Body>
            <Caption mode={CaptionMode.SECONDARY}>{relative(new Date(invitation.createdAt))}</Caption>
            <Eyebrow>
              {invitation.acceptedAt ? `Accepted @ ${relative(new Date(invitation.acceptedAt))}` : 'Pending'}
            </Eyebrow>
          </Section>
        ))}
      </s.List>
      <s.Form>
        {!invitationQuery.loading && (
          <Formik<InvitationForm> initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, submitForm }) => (
              <Form>
                <Caption>Enter multiple comma-separated email addresses</Caption>
                <Textarea name="emails" />
                {role !== Role.User && (
                  <Select name="role">
                    <option value={Role.User}>User</option>
                    {(role === Role.Editor || role === Role.Admin) && (
                      <option value={Role.Editor}>Editor</option>
                    )}
                    {role === Role.Admin && <option value={Role.Admin}>Admin</option>}
                  </Select>
                )}
                <Row spacing={Spacing.GAPS}>
                  {info && info.allowed ? (
                    <Caption>
                      {info.used}/{info.allowed} invitations
                    </Caption>
                  ) : (
                    <div />
                  )}
                  <Button onClick={() => submitForm()} disabled={isSubmitting}>
                    Submit
                  </Button>
                </Row>
                {info && <Text name="invitation-token" value={personalInviteUrl} />}
                <HiddenSubmit />
              </Form>
            )}
          </Formik>
        )}
      </s.Form>
    </s.Layout>
  );
};

export default InvitationManager;
