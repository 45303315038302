import React from 'react';
import styled from '@emotion/styled';

const StyledHiddenSubmit = styled.input(() => ({
  display: 'none',
}));

const HiddenSubmit: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
  return <StyledHiddenSubmit {...props} type="submit" />;
};

export default HiddenSubmit;
