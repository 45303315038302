import React from 'react';
import { useSearchParams, Outlet, Navigate, useLocation } from 'react-router-dom';

import { useSessionRef } from 'renderer/hooks/useCacheState';
import { useGetUserQuery } from './queries';

type PostAuth = {
  type: 'game';
  url: string;
};

export const EnsureAuth: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userQuery = useGetUserQuery();
  const location = useLocation();
  const [postAuth, setPostAuth] = useSessionRef<PostAuth | null>('settings.post-auth', null);
  const auth = userQuery.data?.auth ?? null;

  if (!auth && !userQuery.loading && window.location.href !== '/auth/login') {
    const userInvite = searchParams.get('invite');
    if (!userInvite) {
      setPostAuth({ type: 'game', url: location.pathname });
      return <Navigate to="/auth/login" />;
    }
  }

  if (postAuth) {
    setPostAuth(null);
    return <Navigate to={postAuth.url} />;
  }

  return <Outlet />;
};

export const EnsureInvite: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userQuery = useGetUserQuery();
  const location = useLocation();
  const [postInvite, setPostInvite] = useSessionRef<PostAuth | null>('settings.post-invite', null);
  const auth = userQuery.data?.auth ?? null;

  if (!auth && !userQuery.loading && window.location.href !== '/auth/login') {
    const userInvite = searchParams.get('invite');
    if (userInvite) {
      setPostInvite({ type: 'game', url: location.pathname });
      return <Navigate to={`/auth/invitation/${userInvite}`} />;
    }
  }

  if (postInvite) {
    setPostInvite(null);
    return <Navigate to={postInvite.url} />;
  }

  return <Outlet />;
};
