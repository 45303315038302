import React from 'react';
import styled from '@emotion/styled';

const Path = styled.path(() => ({
  fill: 'currentcolor',
}));

const Success: React.FC = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M6 1.29193L10.7401 9.70645H1.25986L6 1.29193ZM0.170828 9.05968C-0.313887 9.91989 0.290433 11 1.25986 11H10.7401C11.7096 11 12.3139 9.91989 11.8292 9.05968L7.08903 0.645157C6.60432 -0.215052 5.39568 -0.215052 4.91096 0.645157L0.170828 9.05968ZM5.3705 4.53227V5.82581C5.3705 6.18154 5.65377 6.47259 6 6.47259C6.34622 6.47259 6.6295 6.18154 6.6295 5.82581V4.53227C6.6295 4.17654 6.34622 3.88549 6 3.88549C5.65377 3.88549 5.3705 4.17654 5.3705 4.53227ZM5.3705 7.76613H6.6295V9.05968H5.3705V7.76613Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default Success;
