import styled from '@emotion/styled';
import { Grid } from 'renderer/utils/styled';

export const Video = styled.video(() => ({
  width: '100%',
  height: '100%',
}));

export const Area = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gridTemplateRows: '1fr 60px',
  gridTemplateAreas: `
    "main"
    "footer"
  `,
  height: '100%',
}));

export const Main = styled.div(() => ({
  gridArea: 'main',
  height: '100%',
  padding: Grid.M,
  overflowY: 'auto',
}));

export const Overflow = styled.div(() => ({
  overflowY: 'auto',
  height: '100%',
}));

export const Footer = styled.div(() => ({
  gridArea: 'footer',
  padding: Grid.M,
}));
