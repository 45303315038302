import styled from '@emotion/styled';
import { Grid } from 'renderer/utils/styled';

export const Layout = styled.div(() => ({
  display: 'grid',
  gridTemplateRows: 'minmax(0, 1fr)',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
    "list form"
  `,
  gap: Grid.L,
}));

export const List = styled.div(() => ({
  gridArea: 'list',
}));

export const Form = styled.div(() => ({
  gridArea: 'form',
}));
