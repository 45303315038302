import React from 'react';

import * as s from './styles';

export { ProfileSize } from './styles';

type ProfileProps = {
  firstName: string;
  lastName: string;

  avatarUrl?: string | null;
  hideIndicator?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onlinePresence?: boolean;
  size?: s.ProfileSize;
  hideName?: boolean;
};

const Profile: React.FC<ProfileProps> = ({
  onClick,
  avatarUrl,
  firstName,
  lastName,
  //onlinePresence = false,
  size = s.ProfileSize.MEDIUM,
  hideName = false,
}) => {
  return (
    <s.Wrapper onClick={onClick}>
      {avatarUrl ? (
        <s.WithImage onClick={onClick} size={size}>
          <s.Image alt={`${firstName}'s profile`} size={size} src={avatarUrl} crossOrigin="anonymous" />
        </s.WithImage>
      ) : (
        <s.WithoutImage onClick={onClick} size={size}>
          {firstName[0]}
          {lastName[0]}
        </s.WithoutImage>
      )}
      {((size !== s.ProfileSize.SMALL && size !== s.ProfileSize.TINY) || !hideName) && (
        <s.Name>
          {firstName} {lastName}
        </s.Name>
      )}
      {/*<s.Indicator onlinePresence={onlinePresence} size={size} />*/}
    </s.Wrapper>
  );
};

export default Profile;
