import React from 'react';
import styled from '@emotion/styled';
import { Weight } from 'renderer/utils/styled';

export enum FocusMode {
  PRIMARY,
  SECONDARY,
}
type FocusProps = ModeProps & {
  children: string | React.ReactNode;
};

type ModeProps = {
  mode?: FocusMode;
};
const Copy = styled.div<ModeProps>(({ theme, mode = FocusMode.PRIMARY }) => [
  {
    fontSize: 120,
    fontWeight: Weight.BLACK,
    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
  },
  mode === FocusMode.PRIMARY && {
    color: theme.colors.white,
  },
  mode === FocusMode.SECONDARY && {
    color: theme.colors.gray4,
  },
]);

const Focus: React.FC<FocusProps> = ({ children, mode = FocusMode.PRIMARY }) => {
  return <Copy mode={mode}>{children}</Copy>;
};

export default Focus;
