import { ThemeProvider } from '@emotion/react';
import React from 'react';

import useMousetrap from 'renderer/hooks/useMousetrap';
import Cancel from 'renderer/icons/Cancel';
import { darkTheme } from 'renderer/utils/styled';

import * as s from './styles';

export const Content: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Content.displayName = 'content';
export const Header: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Header.displayName = 'header';

type DialogProps = {
  onClose?: () => void;
  width?: number;
  children: React.ReactNode;
};

const Dialog: React.FC<DialogProps> = ({ children, onClose, width = 700 }) => {
  useMousetrap('escape', () => {
    if (onClose) onClose();
  });

  const nodes = React.Children.toArray(children);

  // @ts-ignore
  const content = nodes.filter((child) => child.type.displayName === 'content');
  // @ts-ignore
  const header = nodes.filter((child) => child.type.displayName === 'header');

  return (
    <ThemeProvider theme={darkTheme}>
      <s.Wrapper>
        <s.Main width={width}>
          <s.HeaderLine>
            {header}
            {onClose && (
              <s.Close onClick={onClose}>
                <Cancel />
              </s.Close>
            )}
          </s.HeaderLine>
          <s.Content>{content}</s.Content>
        </s.Main>
      </s.Wrapper>
    </ThemeProvider>
  );
};
Dialog.displayName = 'dialog';

export default Dialog;
