import React from 'react';

import Hero, { Main, RightSidebar } from 'renderer/components/templates/Hero';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';
import WindowsLogo from './windows-logo-2021.png';
import UbuntuLogo from './ubuntu-logo.png';
import MacosLogo from './macos-logo.png';

import Title2 from 'renderer/system/typography/Title2';
import Profile, { ProfileSize } from 'renderer/system/molecules/Profile';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import Separator from 'renderer/system/atoms/Separator';
import Card, { CardModes } from 'renderer/system/molecules/Card';

import { useGetUserQuery } from './queries';

const downloadables = {
  windows:
    'https://storage.googleapis.com/studio_builds/master/2024_04_25-23_13_29/Windows/UltimateGamesStudio-v0.4.94.zip',
  linux:
    'https://storage.googleapis.com/studio_builds/master/2024_04_25-22_09_33/Linux/UltimateGamesStudio-0.4.94.zip',
  macApple:
    'https://storage.googleapis.com/studio_builds/master/2024_04_25-18_58_53/Darwin/UltimateGamesStudio-0.4.94-arm64-mac.zip',
  macIntel:
    'https://storage.googleapis.com/studio_builds/master/2024_04_25-18_58_53/Darwin/UltimateGamesStudio-0.4.94-mac.zip',
};

const DownloadManager: React.FC = () => {
  const userQuery = useGetUserQuery();
  const user = userQuery.data?.auth?.user ?? null;

  if (!user || userQuery.loading)
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Not logged in</Title2>
        </RightSidebar>
      </Hero>
    );

  return (
    <Hero
      backgroundImage={SkyImage}
      keystone={
        user && <Profile firstName={user.firstName} lastName={user.lastName} size={ProfileSize.MEDIUM} />
      }
    >
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        <Section mode={SectionMode.Focus}>
          <Title2>Welcome, {user.firstName}</Title2>
        </Section>
        <Separator />
        <Section vertical={2}>
          <Card
            header="Mac (Arm)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(downloadables.macApple);
              },
            }}
            image={{
              source: MacosLogo,
              description: 'Mac (Apple-silicon) logo',
            }}
          />
        </Section>
        <Section vertical={2}>
          <Card
            header="Mac (64 bits)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(downloadables.macIntel);
              },
            }}
            image={{
              source: MacosLogo,
              description: 'Mac (Intel) logo',
            }}
          />
        </Section>
        <Section vertical={2}>
          <Card
            header="Windows (64 bits)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(downloadables.windows);
              },
            }}
            image={{
              source: WindowsLogo,
              description: 'Windows logo',
            }}
          />
        </Section>
        <Section vertical={2}>
          <Card
            header="Linux (64 bits)"
            mode={CardModes.HORIZONTAL}
            secondary={{
              label: 'Download',
              action: () => {
                window.open(downloadables.linux);
              },
            }}
            image={{
              source: UbuntuLogo,
              description: 'Ubuntu (linux) logo',
            }}
          />
        </Section>
      </RightSidebar>
    </Hero>
  );
};

export default DownloadManager;
