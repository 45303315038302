import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalRef, useSessionRef } from 'renderer/hooks/useCacheState';
import Eyebrow from 'renderer/system/typography/Eyebrow';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  return <Eyebrow onClick={() => navigate('/auth/login')}>Login</Eyebrow>;
};

export const Logout: React.FC = () => {
  const [, setAccessToken] = useLocalRef('settings.access-token', null);
  const [, setPostAuth] = useSessionRef<string | null>('settings.post-auth', null);
  const handleLogout = useCallback(() => {
    setPostAuth(null);
    setAccessToken(null);
    window.location.href = '/';
  }, [setAccessToken, setPostAuth]);
  return <Eyebrow onClick={handleLogout}>Logout</Eyebrow>;
};
