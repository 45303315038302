import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';

import Hero, { Main, RightSidebar, Searchbar } from 'renderer/components/templates/Hero';
import Dialog, {
  Header as DialogHeader,
  Content as DialogContent,
} from 'renderer/components/templates/Dialog';

import InvitationManager from 'renderer/components/panels/InvitationManager';

import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';

import Card, { CardModes } from 'renderer/system/molecules/Card';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import Profile, { ProfileSize } from 'renderer/system/molecules/Profile';
import Title2 from 'renderer/system/typography/Title2';
import Body from 'renderer/system/typography/Body';
import { useUi } from 'renderer/data/ui';
import UserIcon from 'renderer/icons/User';
import Button, { ButtonModes, ButtonSizes } from 'renderer/system/atoms/Button';
import Row, { Alignment, Spacing } from 'renderer/system/misc/Row';

import * as s from './styles';

import { useGetUserQuery, useCreateSessionMutation } from './queries';
import Eyebrow from 'renderer/system/typography/Eyebrow';
import { Login, Logout } from 'renderer/components/misc/LoginButton';

type Branch = {
  shortname: string;
};

const BranchSelection: React.FC = () => {
  const [, setSearchParams] = useSearchParams();

  const { shortname } = useParams<Branch>();
  const ui = useUi();
  const navigate = useNavigate();
  const userQuery = useGetUserQuery({ variables: { shortname: shortname ?? '' } });
  const user = userQuery.data?.auth?.user ?? null;
  const game = userQuery.data?.gameByShortname ?? null;
  const branches = game?.branches ?? [];
  const [createSession, createStatus] = useCreateSessionMutation();

  const handleCreateSession = (branchId: string) => async () => {
    const session = await createSession({ variables: { branchId } });
    const id = session.data?.createSessionWithBranch.id ?? null;
    if (!id) {
      return;
    }

    navigate(`/game/session/${id}`);
  };

  const info = userQuery.data?.auth?.invitationInfoByShortname;

  useEffect(() => {
    if (!info) return;

    setSearchParams({ invite: info.invitationToken }, { replace: true });
  }, [info, setSearchParams]);

  if (userQuery.loading || (createStatus.loading && createStatus.called)) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  if (!game) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>You don&apos;t have access to this game</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  return (
    <Hero
      backgroundImage={SkyImage}
      keystone={
        user && (
          <Profile hideName firstName={user.firstName} lastName={user.lastName} size={ProfileSize.MEDIUM} />
        )
      }
    >
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        {user ? (
          <Section mode={SectionMode.Focus}>
            <Title2>Welcome back, {user.firstName}</Title2>
          </Section>
        ) : (
          <Section mode={SectionMode.Focus}>
            <Title2>Welcome</Title2>
          </Section>
        )}
        <Section mode={SectionMode.Focus}>
          <Title2>{game.name}</Title2>
        </Section>
        {branches.length <= 0 ? (
          <Eyebrow>No branches published yet</Eyebrow>
        ) : (
          <div>
            <Body>Select a branch to create a game from</Body>
            <s.Scroll>
              {branches.map((branch) => (
                <Section key={branch.id} vertical={2} horizontal={0}>
                  <Card
                    header={branch.name}
                    mode={CardModes.HORIZONTAL}
                    secondary={{ label: 'New Game', action: handleCreateSession(branch.id) }}
                  ></Card>
                </Section>
              ))}
            </s.Scroll>
          </div>
        )}
      </RightSidebar>
      {user ? (
        <Searchbar>
          <Row spacing={Spacing.GAPS} alignment={Alignment.CENTER}>
            <div>
              <Logout />
            </div>
            <div>
              <Row alignment={Alignment.CENTER} onClick={() => ui.openDialog('invitation')}>
                <Eyebrow>Invite</Eyebrow>
                <Button mode={ButtonModes.QUATERNARY} size={ButtonSizes.SMALL}>
                  <UserIcon />
                </Button>
              </Row>
            </div>
          </Row>
        </Searchbar>
      ) : (
        <Searchbar>
          <Row spacing={Spacing.GAPS} alignment={Alignment.CENTER}>
            <div />
            <div>
              <Login />
            </div>
          </Row>
        </Searchbar>
      )}
      {ui.isDialogOpen('invitation') && (
        <Dialog onClose={() => ui.closeDialog('invitation')}>
          <DialogHeader>Invite New User</DialogHeader>
          <DialogContent>
            <InvitationManager gameId={game.id} />
          </DialogContent>
        </Dialog>
      )}
    </Hero>
  );
};

export default observer(BranchSelection);
