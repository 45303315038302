import React from 'react';
import { groupBy } from 'lodash';

import * as s from './styles';

import { relative } from 'renderer/utils/datetime';

type CommentProps = {
  title: string;
  time: Date;
  content?: string | React.ReactNode | null;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const Left: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Left.displayName = 'left';

export const Footer: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Footer.displayName = 'footer';

const Comment: React.FC<CommentProps> = ({ content, time, children, title, onClick }) => {
  const nodes = React.Children.toArray(children);

  const {
    left,
    footer,
    // @ts-ignore
  } = groupBy(nodes, (node) => node.type.displayName);

  return (
    <s.Main onClick={onClick}>
      {left && <div>{left}</div>}
      <s.Wrapper>
        <s.Title>{title}</s.Title>
        <s.Section>
          <s.Time>{relative(time)}</s.Time>
        </s.Section>
        <s.Content>{content && <s.Body>{content}</s.Body>}</s.Content>
        {footer && <s.Footer>{footer}</s.Footer>}
      </s.Wrapper>
    </s.Main>
  );
};

export default Comment;
