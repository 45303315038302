import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Hero, { Main, RightSidebar, Searchbar } from 'renderer/components/templates/Hero';
import Focus from 'renderer/system/typography/Focus';

import SkyImage from 'renderer/images/sky.png';

import Card, { CardModes } from 'renderer/system/molecules/Card';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import Profile, { ProfileSize } from 'renderer/system/molecules/Profile';
import Title2 from 'renderer/system/typography/Title2';

import { useGetUserQuery } from './queries';
import Row, { Alignment, Spacing } from 'renderer/system/misc/Row';
import { Login, Logout } from 'renderer/components/misc/LoginButton';
import Eyebrow from 'renderer/system/typography/Eyebrow';

const GameSelection: React.FC = () => {
  const userQuery = useGetUserQuery();
  const navigate = useNavigate();
  const user = userQuery.data?.auth?.user ?? null;
  const games = userQuery.data?.games ?? [];

  if (userQuery.loading) {
    return (
      <Hero backgroundImage={SkyImage}>
        <Main>
          <Focus>Ultimate Engine</Focus>
        </Main>
        <RightSidebar>
          <Title2>Loading</Title2>
        </RightSidebar>
      </Hero>
    );
  }

  return (
    <Hero
      backgroundImage={SkyImage}
      keystone={
        user && <Profile firstName={user.firstName} lastName={user.lastName} size={ProfileSize.MEDIUM} />
      }
    >
      <Main>
        <Focus>Ultimate Engine</Focus>
      </Main>
      <RightSidebar>
        {user ? (
          <Section mode={SectionMode.Focus}>
            <Title2>Welcome back, {user.firstName}</Title2>
          </Section>
        ) : (
          <Section mode={SectionMode.Focus}>
            <Title2>Welcome</Title2>
          </Section>
        )}
        <Title2>Create a new game</Title2>
        <div>
          {games.length <= 0 && <Eyebrow>No games available yet</Eyebrow>}
          {games.map((game) => (
            <Section key={game.id} vertical={2} horizontal={0}>
              <Card
                header={game.name}
                subheader={game.company.name}
                mode={CardModes.HORIZONTAL}
                secondary={{
                  label: 'View',
                  action: () => {
                    navigate(`/game/${game.shortname.toLowerCase()}`);
                  },
                }}
              ></Card>
            </Section>
          ))}
        </div>
      </RightSidebar>
      {!user ? (
        <Searchbar>
          <Row spacing={Spacing.GAPS} alignment={Alignment.CENTER}>
            <div>
              <Login />
            </div>
            <div />
          </Row>
        </Searchbar>
      ) : (
        <Searchbar>
          <Logout />
        </Searchbar>
      )}
    </Hero>
  );
};

export default observer(GameSelection);
